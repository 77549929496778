import { http } from '../utils/http'
import React from "react";
import '../static/chat.css'
import { T } from 'tldraw';



function MessageTemplate({ text, side }) {
    return (
        <>
            <div className="message_template appeared">
                <li className="message">
                    <div className="avatar"></div>
                    <div className="text_wrapper">
                        <div className="text">{text}</div>
                    </div>
                </li>
            </div>
        </>

    )
}



class ChatsView extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)
        this.attr = { id: params.id }



        this.state = {
            records: [],
            message: ""
        }



        this.loadRecords = this.loadRecords.bind(this)
    }

    componentDidMount() {

        this.loadRecords();
    }


    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id })
        };
        http.YGet(`/api/chatrecords?id=` + self.attr.id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            });
            setTimeout(function(){
                document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight+60;
            },30);
        })
    }

    handleQuerySelect() {
        let message_input = document.getElementById('message_input');


        const text = message_input.value;
        const self = this; 
        if( text.length<1 || text.indexOf('AI正在思考中..')>-1){
            alert('请输入内容后，再提交。')
            return;
        }
        message_input.value = 'AI正在思考中..';
        message_input.disabled = true;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id':  self.attr.id, 'data': text })
        };

        let records = this.state.records;
        records.push({'uid':self.attr.id,'contents':text,'date':'00:00:00'})

        self.setState({ records: records })
        http.YGet(`/api/chat`, requestOptions, function (data) {
            message_input.disabled = false;
            message_input.value='';
            records.push({'uid':0,'contents':data.data.message,'date':'00:00:00'})
            self.setState({ records: records })
            setTimeout(function(){
                document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight+60;
            },30);
        })
        setTimeout(function(){
            document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight+60;
        },30);
  

    }




    render() {
        const { records } = this.state;

        return (<div className=' '>
            <div className="chat_window">
                <div className="top_menu">
                    <div className="buttons" onClick={e => { window.history.back()  }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                        </svg>
                    </div>
                    <div className="title">AI 在线</div>
                </div>
                <ul className="messages" id="messages">
                    {records && records.length > 0 &&
                        <> 
                            {records.map((item, index) =>  
                                <li className={`message appeared ${item.uid>0 ? 'left' : 'right'}  `}  key={index}>
                                    <div className="avatar">
                                        
                                        { item.uid>0 && <>ME</>}
                                        { item.uid<1 && <>AI</>}
                                        </div>
                                    <div className="text_wrapper">

                                        <div className="text">{item.contents}</div>
                                        <div className='date'>{item.create_time}</div>
                                    </div>
                                </li> 
                            )
                            } 
                        </>
                    }




                </ul>
                <div className="bottom_wrapper clearfix">
                    <div className="message_input_wrapper">
                        <input className="message_input" placeholder="请输入你的问题.." id="message_input"/>
                    </div>
                    <div className="send_message">
                        <div className="icon"></div>
                        <div className="text" onClick={ e=>{ this.handleQuerySelect()}}>发送</div>
                    </div>
                </div>
            </div>
            <div className="message_template">
                <li className="message">
                    <div className="avatar"></div>
                    <div className="text_wrapper">
                        <div className="text"></div>
                    </div>
                </li>
            </div>
        </div>)
    }

}

export { ChatsView };