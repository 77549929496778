
import { http } from '../utils/http'
import _ from 'lodash' 
import React from "react";

import { Editor, Tldraw, useValue, exportAs, exportToBlob, DefaultBrush, DefaultDashStyle, DefaultSizeStyle } from 'tldraw'

import 'tldraw/tldraw.css'

const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

/* 压缩base64图片，怎么压缩base64是题外话，这里不赘述 */
function compress(
    base64,        // 源图片
    rate,          // 缩放比例
    callback       // 回调
) {
    //处理缩放，转格式
    var _img = new Image();
    _img.src = base64;
    _img.onload = function () {
        var _canvas = document.createElement("canvas");
        var w = this.width / rate;
        var h = this.height / rate;
        _canvas.setAttribute("width", w);
        _canvas.setAttribute("height", h);
        _canvas.getContext("2d").drawImage(this, 0, 0, w, h);
        var base64 = _canvas.toDataURL("image/jpeg");
        _canvas.toBlob(function (blob) {
            if (blob.size > 750 * 1334) {        //如果还大，继续压缩
                compress(base64, rate, callback);
            } else {
                callback(base64);
            }
        }, "image/jpeg");
    }
}

class WhriteBoard extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = { }; 
        for (const param of searchParams) { 
            const [key, value] = param.split('=');
            params[key] = value;
        } 
        console.log(params)




        this.state = {
            subject: null,
            records: [],
            message: " 请在右边写出您的内容，完成后提交。",
            score:0
        }
        this.attr = {  id: params.id, "signpad": null, "undoData": [], "editor": null }

        this.handleQuerySelect = this.handleQuerySelect.bind(this)
        this.loadRecords = this.loadRecords.bind(this)





    }

    componentDidMount() {

        const self = this

        // var canvas = document.querySelector("canvas");
        // var signaturePad = new SignaturePad(canvas);
        // this.attr['signpad'] = signaturePad;
        // signaturePad.addEventListener("endStroke", () => {
        //     // clear undoData when new data is added
        //     self.attr.undoData = [];
        //   });


        // //窗口缩放事件
        // window.onresize = function () {
        //     self.redraw()
        // }
        // self.redraw()
        //this.loadRecords()

        this.loadSubject(this.attr.id);
    }

    loadSubject(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subject?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/records`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }


    handleQuerySelect() {
        const self = this;
        let shapes = this.attr.editor.getCurrentPageShapeIds();
        if (shapes.size < 1) {
            window.alert("请在右边写出您的内容，完成后提交。")
            return null;
        }
        console.log(shapes)
        const blob = exportToBlob({ editor: this.attr.editor, shapes, format: "jpeg" }).then(function (blob) {
            //console.log(blob)
            const file = new File([blob], 'a', { type: blob.type });
            let b64Data = blobToBase64(file).then(function (b64Data) {
                //console.log(b64Data)
                //self.handleQuerySelect(b64Data)


                // const requestOptions = {
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json' },
                //     body: JSON.stringify({ 'id': 0, 'data': b64Data })
                // };

                // self.setState({ message: String('正在识别中..') })
                // http.YGet(`/api/recognize`, requestOptions, function (data) {
                //     var content = String(data.data.message)
                //     if (content.length < 1) {
                //         self.setState({ message: String('没有识别到书写的内容，请重新书写') })
                //     } else {
                //         console.log(data)
                //         self.setState({
                //             message: String(data.data.message)
                //         })
                //     }

                // })

                console.log("b64Data.length", b64Data.length)


                compress(b64Data, 2, function (base64) {
                    //console.log(base64) 
                    console.log("base64.length", base64.length)
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'id':  self.attr.id, 'data': base64 })
                    };

                    self.setState({ message: String('正在识别中..') })
                    http.YGet(`/api/recognize`, requestOptions, function (data) {
                        var content = String(data.data.message)
                        if (content.length < 1) {
                            self.setState({ message: String('没有识别到书写的内容，请重新书写') })
                        } else {
                            console.log(data)
                            self.setState({
                                message: String(data.data.message),
                                score:data.data.score
                            })
                        }

                    })

                })
            })
        });

        //var data = []

    }


    render() {
        const { records, message, score , subject } = this.state;
        const self = this;
        console.log(records)
        return (<div className='disable-select'>
 

            <div className="d-flex flex-column write_pad ">


                <div className='head-tool'>
                    <div className=" align-items-center t-box">
                        <div className="t-menu ">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>

                        </div>
                        <div className="a-auto t-submit">
                            {subject && <span className=''>{subject.name} </span>}
                            <span className='r-score2'>{score} 分</span>&nbsp;
                            <button type="button" className="btn btn-primary" onClick={this.handleQuerySelect}>提交</button>

                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row pad-tool'>
                    <div className="   bd-highlight _left">
                        {/* <div className='pad-title ' >


                            <div className="row align-items-start">
                                <div className="col">
                                    当前默写:我的房间
                                </div>

                                <div className="col text-end ">
                                    <div className='r-score'>{score} 分</div>
                                    
                                </div>
                            </div>


                        </div> */}
                        <div className='pad-text'>{message}</div>

                    </div>
                    <div className="  bd-highlight _right">


                        <Tldraw components={{
                            ContextMenu: null,
                            ActionsMenu: null,
                            HelpMenu: null,
                            //ZoomMenu: null,
                            MainMenu: null,
                            Minimap: null,
                            StylePanel: null, //这个是选择笔画的面板
                            PageMenu: null,
                            NavigationPanel: null,
                            //Toolbar: null,
                            KeyboardShortcutsDialog: null,
                            QuickActions: null,
                            HelperButtons: null,
                            DebugPanel: null,
                            DebugMenu: null,
                            SharePanel: null,
                            //MenuPanel: null,
                            TopPanel: null,
                            // DefaultBrush:{{h:1,w:1,x:0,y:0}}
                        }}
                            // options={{ animationMediumMs: 300 }}

                            onMount={(editor) => {
                                self.attr['editor'] = editor;

                                // editor.setCameraOptions({
                                //     zoom: 0.1,
                                // }) 

                                // your editor code here
                                console.log("---")
                                editor.setCurrentTool('draw')

                                //editor.setStyleForNextShapes(DefaultBrush,{h:1,w:1,x:0,y:0});
                                //editor.setStyleForNextShapes(DefaultDashStyle, 'dotted');
                                editor.setStyleForNextShapes(DefaultSizeStyle, "s");
                                //editor.setStyleForNextShapes(DefaultBrush, { h: 1, w: 1, x: 0, y: 0 });
                                //editor.set
                                editor.store.listen(change => {
                                    //console.log(change)

                                    // Added
                                    for (const record of Object.values(change.changes.added)) {
                                        if (record.typeName === 'shape') {
                                            console.log(`created shape (${record.type})\n`)
                                        }
                                    }

                                    // Updated
                                    for (const [from, to] of Object.values(change.changes.updated)) {
                                        if (
                                            from.typeName === 'instance' &&
                                            to.typeName === 'instance' &&
                                            from.currentPageId !== to.currentPageId
                                        ) {
                                            console.log(`changed page (${from.currentPageId}, ${to.currentPageId})`)
                                        } else if (from.id.startsWith('shape') && to.id.startsWith('shape')) {

                                            let diff = _.reduce(
                                                from,
                                                (result, value, key) =>
                                                    _.isEqual(value, to[key]) ? result : result.concat([key, to[key]]),
                                                []
                                            )
                                            if (diff?.[0] === 'props') {
                                                diff = _.reduce(
                                                    (from).props,
                                                    (result, value, key) =>
                                                        _.isEqual(value, (to).props[key])
                                                            ? result
                                                            : result.concat([key, (to).props[key]]),
                                                    []
                                                )
                                            }

                                            let dataString = JSON.stringify(diff)
                                            if (dataString.indexOf("isComplete") > -1) {
                                                console.log(" isComplete ");

                                                // let shapes = editor.getCurrentPageShapeIds();
                                                // const blob = exportToBlob({ editor: editor, shapes, format: "png" }).then(function (blob) {
                                                //     //console.log(blob)
                                                //     const file = new File([blob], 'a', { type: blob.type });
                                                //     let b64Data = blobToBase64(file).then(function (b64Data) {
                                                //         //console.log(b64Data)
                                                //         //self.handleQuerySelect(b64Data)
                                                //     })
                                                // });

                                                //console.log(b64Data)

                                            }

                                            //console.log(`updated shape (${JSON.stringify(diff)})\n`)
                                        }
                                    }

                                    // Removed
                                    for (const record of Object.values(change.changes.removed)) {
                                        if (record.typeName === 'shape') {
                                            console.log(`deleted shape (${record.type})\n`)
                                        }
                                    }


                                }, {})
                            }}>
                        </Tldraw>



                    </div>
                </div>
            </div>

            {/* {records.length > 0 &&
                <div>
                    <ul>
                        {records.map((item, index) =>
                            <li className='query_item' key={index} >
                                {item.result}, {item.create_time}
                            </li>
                        )
                        }
                    </ul>
                </div>
            } */}
        </div>)
    }

}

export { WhriteBoard };