
import { http } from '../utils/http'
import React from "react";
import { Outlet, Link } from "react-router-dom";


class ScoreProductPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [], 
            username: '',
            score: 0,
            progress: 0,
            message: ""
        }
        this.attr = { "signpad": null, "undoData": [], "timeoutID": 0 }

        this.loadRecords = this.loadRecords.bind(this)
 

    }

    componentDidMount() {
        const self = this
        this.loadRecords(); 
    }
 

    loadRecords() {
        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/scoreproducts`, requestOptions, function (data) {
            console.log(new Date(), data)
             self.setState({
                 records: data.data.data,
            score: data.data.score
             })
 
        })
    }

 
    render() {
        const { records, message } = this.state;

        return (<div className='  disable-select  '>




            <div className='head-tool-w'>

                <div className="d-flex justify-content-between t-up">
                    <div className="col1">
                        <div className="buttons pt-1 pl-2" onClick={e => { window.history.back() }} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                            </svg>
                        </div>
                    </div>
                    <div className="col2">


                        积分兑换 {this.state.score}

                    </div>
                    <div className="col3">

   
                    </div>
                </div>


            </div>
            <div>

                
            {records && records.length > 0 &&
                            <div className='p-list'>
                                <ol>
                                    {records.map((item, index) =>
                                        <li className='item d-flex flex-column justify-content-center' key={index} >
                                            <div>
                                            <div className='t1'>{item.name}</div> 
                                            <div className='sc'> 
                                               积分-{item.score}
                                            </div>
                                            </div>
                                           
                                        </li>
                                    )
                                    }
                                </ol>
                            </div>
                        }

            </div>

        </div>)
    }

}

export { ScoreProductPage };