
import { http } from '../utils/http'
import _ from 'lodash'
import React, { useRef } from "react";

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import axios from 'axios';

function sleep(delay) {
    var start = (new Date()).getTime();
    while ((new Date()).getTime() - start < delay) {
        continue;
    }
}

function addAudioElement2(blob) {

    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
    console.log(url)


    // var x = document.createElement("AUDIO");

    // if (x.canPlayType("audio/mpeg")) {
    //   x.setAttribute("src","horse.mp3");
    // } else {
    //   x.setAttribute("src","horse.ogg");
    // }

    // x.setAttribute("controls", "controls");
    document.getElementById("audio_list").appendChild(audio);
}

function sliceBlob(blob, start, end, type) {
    type = type || blob.type;
    if (blob.mozSlice) {
        return blob.mozSlice(start, end, type);
    } else if (blob.webkitSlice) {
        return blob.webkitSlice(start, end, type);
    } else {
        throw new Error("This doesn't work!");
    }
}


//创建一个新的组件
const ExampleComponent = ({ id, callback, prompt, bindComponent }) => {

    const recorderControls = useAudioRecorder()
    const upload_file_id = String(Math.random() * (10000 - 100000) + 100000);
    const file_bold_cache = {}

    var alert_num = 0;

    //给个文件名的随机数，避免重复， 也可以用分割的份数ID来代替，可能更好一些。
    //let r = String(myDate.getTime()); //String( Math.random()*(10000-100000) + 100000 );

    bindComponent( recorderControls );


    const uploader = (key, blob, start, end, totalSize, randomvalue, callbackForPercent) => {

        // 获取blob数据
        // const blob = new Blob([postString], { type: 'text/plain;charset=utf-8' })

        // 创建FormData对象
        let formData = new FormData();

        //上传时，文件名需要把重要信息标记在一起
        formData.append('upfile', blob, 'f_' + start + '_' + end + '_' + totalSize + '.mp3'); // 第一个参数是后台接收的文件参数名，第二个参数是blob数据，第三个参数是文件名
        //文件唯一标识
        formData.append("r", randomvalue);
        // 发送ajax请求
        axios.post(`/api/recoaudio?id=` + id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            //console.log('respons:' + key); 
            //console.log(response.data);
            if (response.data.code == 200) {

                //console.log("updoad sucess!");

                //删除缓存
                delete file_bold_cache[key];

                // 处理响应数据
                //addAudioElement2(blob);
                callbackForPercent(key)

                //日志
                //console.log("file_bold_cache.length=" + Object.keys(file_bold_cache).length);
                if(alert_num>0){
                    alert_num = alert_num-1;
                }
                //只有有一个正常，说明网络正常了，可以上传成功，清空提示
                prompt('');

            }

        }).catch(error => { 
            //记录错误数量alert_num
            alert_num = alert_num+1;
            // 处理错误
            prompt("上传异常("+String(alert_num)+")，请检测网络信号，网络正常(30秒后），会自动上传！"); 

        });

    }

 
    const submitAudio = (blob) => {

        //alert(blob.type + '' + blob.size);

        // let start = 0;
        // let end = blob.size;
        // // 截取blob数据
        // for (let i = 0; i < blob.size; i++) {
        //     if (blob[i] == 137) {
        //         start = i + 4;
        //     }
        // }

        // let blob1 = sliceBlob(blob, start, end);

        const LENGTH = 1024 * 100;//每次上传的大小 
        var totalSize = blob.size;//文件总大小
        var start = 0;//每次上传的开始字节
        var end = start + LENGTH;//每次上传的结尾字节

        //uploader2(blob, start, end, totalSize);

        let percentNum = 0;

        //总份数
        let totalNum = parseInt(totalSize / (end - start));
        let percent = 0;
        //let updateNum = 0;
        var startDate = new Date();

        function callbackForPercent(key) {
            percentNum++;
            //updateNum = updateNum+1;
        }

        //更新进度
        function updatePercent() {

            //防止长时间的上传
            console.log("new Date().getTime()-startDate.getTime()=" + (new Date().getTime() - startDate.getTime()))
            if ((new Date().getTime() - startDate.getTime()) / 1000 > (totalNum + 1) * 30) {
                return;
            }

            //如果进度条上传到99时，认为成功了。
            if (percent > 99) {
                callback(100);
            } else {

                //没有分割文件，直接上传完成
                if (totalNum < 1) {

                } else {
                    percent = parseInt(percentNum * 100 / totalNum);
                    console.log("percent=" + percent + " percentNum=" + percentNum + "totalNum=" + totalNum);
                    callback(percent);
                    setTimeout(updatePercent, 300);
                }

            }

            //检测上传的部分文件，有没有上传成功， 如果30秒没有成功， 则需要重新上传
            for (var val in file_bold_cache) {
                //console.log(val + " use seconds=" + (new Date().getTime() - file_bold_cache[val][7].getTime()) / 1000);//输出 
                
                //超过30秒没有上传完成，重新上传
                if ((new Date().getTime() - file_bold_cache[val][7].getTime()) / 1000 > 30) {
                    startDate = new Date();
                    file_bold_cache[val][7] = new Date();
                    let p = file_bold_cache[val]
                    uploader(p[0], p[1], p[2], p[3], p[4], p[5], callbackForPercent);
                }
            }
        }

        //更新进度条
        updatePercent();


        //开始上传
        while (start < totalSize) {

            if (end > totalSize) {
                end = totalSize;
            }
            //分割文件
            let blob1 = blob.slice(start, end); //sliceBlob(blob,start, end, blob.type);//file.slice(start, end);//根据长度截取每次需要上传的数据
            let key = String(start) + String(end) + String(totalSize) + String(upload_file_id);
            if ( file_bold_cache.hasOwnProperty(key) ) {

            } else {
                //保存部分缓存，并上传
                file_bold_cache[key] = [key, blob1, start, end, totalSize, upload_file_id, callbackForPercent, new Date()];
                uploader(key, blob1, start, end, totalSize, upload_file_id, callbackForPercent);
            }


            //callback(start, end, totalSize);
            // if( end > 1){
            //     return;
            // }

            //重新设置开始和结尾
            start = end;
            end = start + LENGTH;
        }
        //callback(100);
        addAudioElement2(blob); 

    } 

    return (
        <div>
            <AudioRecorder
                onRecordingComplete={submitAudio}
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                }}
                recorderControls={recorderControls}

                downloadFileExtension="mp3"
            />
            <div className='pt-2'>
                <button onClick={recorderControls.stopRecording}>完成</button>
            </div> 
        </div>
    )
}


class Dotask extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)

        this.state = {
            subject: null,
            records: [],
            message: "",
            score: 0,
            info: '',
            percent: -1
        }
        this.attr = { id: params.id, "signpad": null, "undoData": [], "editor": null, "upload_num": 0, audioComponent:null }
        this.loadTask = this.loadTask.bind(this);
        this.uploadProcess = this.uploadProcess.bind(this);
        this.warnPrompt = this.warnPrompt.bind(this);
        this.bindComponent = this.bindComponent.bind(this);
    }

    componentDidMount() {

        const self = this

        this.loadTask(this.attr.id);
    }
    componentWillUnmount() {
        console.log('Dotask componentWillUnmount stopRecording');
        this.attr.audioComponent.stopRecording();
    }
    loadTask(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/task?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }
    uploadProcess(percent) {
        this.setState({ percent: percent });

        // if (percent == 0) {
        //     document.getElementById("uploadstate").innerHTML='上传中.';
        // }else if (percent == 100) {
        //     document.getElementById("uploadstate").innerHTML='上传完成.';
        //     setTimeout(function () { 
        //         document.getElementById("uploadstate").innerHTML='';
        //     },1000);
        // }

        // if( start == 0 && end == 0){
        //     this.setState({ percent: 0 });  
        //     this.attr.upload_num = 0;
        // }else{

        //     this.attr.upload_num += 1;
        //     let totalNum = parseInt(totalSize/(end-start))

        //     if( totalNum == this.attr.upload_num ){
        //         this.setState({ percent: 100 });  
        //     }else{
        //         this.setState({ percent: parseInt(100*(this.attr.upload_num)/totalNum) });  
        //     }

        // }

    }

    warnPrompt(info) {
        this.setState({ info: info });
    }

    bindComponent( com){
        this.attr.audioComponent = com;
    }



    render() {
        const { records, message, score, subject, percent, info } = this.state;
        const self = this;
        console.log(records)
        return (<div className='disable-select'>


            <div className=' pb-5 '>
                <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>
            </div>



            <div className=' p-5'>

                {info.length > 0 &&
                    <div class="alert alert-warning" role="alert">
                        {info}
                    </div>
                }

                <div id="uploadstate" >

                    {percent >= 0 && percent < 100 &&
                        <div className='' >
                            <h5>上传中..</h5>
                            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar  bg-info" style={{ width: `${percent}%` }} >{percent}%</div>
                            </div>
                        </div>
                    }
                    {percent >= 100 && <div> 上传完成.</div>}


                </div>

                <ExampleComponent id={this.attr.id} callback={this.uploadProcess} prompt={this.warnPrompt} bindComponent={this.bindComponent} />

                <div className='pad-text'>{message}</div>
                <div id="audio_list" >

                </div>


            </div>

        </div>)
    }

}

export { Dotask };