import React, { useMemo, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import SignaturePad from 'signature_pad';
import { http } from '../utils/http'

function toDate( date ){
    let timer = new Date(date)
    return timer.getDate().toString().padStart(2, '0')+' ' +timer.toLocaleTimeString()
}




class SubjectSelectPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            message: " 请在下面书写内容，完成后提交。"
        }
        this.attr = { "signpad": null } 
        this.loadRecords = this.loadRecords.bind(this)
 


    }

    componentDidMount() {
       
        this.loadRecords()

    }
  

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/subjects`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }

 

    render() {
        const { records, message } = this.state;

        console.log(records)
        return (<div>

                <div className='container  subject_list' >

                
                <div className="accordion shadow mb-5" id="accordionAuth">
                    <div className="accordion-item">
                        <div className="d-flex align-items-center justify-content-between px-4 py-5">
                            <div className="me-3">
                                <h5 className="mb-0">请选择下面需要默写的内容。</h5> 
                            </div>
                            <div className="badge bg-success-soft rounded-pill text-success">{records.length}</div>
                        </div>
                    </div>
       
                    {records.map((item, index) =>
                        <div className='accordion-item' key={index} >
                            <h5 className=' p-2 px-4'>
                            <span className='name'><Link to={`/writekey/${item.id}?id=${item.id}`}>{item.name}</Link></span>

                            {/* <span className='name'> {item.name} </span> */}


                            <span className='r-score' >{item.score} </span>
                            
                            
                            { item.type_id == 1 && <span>
                                <Link to={`/listionwrite/${item.id}?id=${item.id}`}>读写</Link>
                                </span>}
                                { item.type_id == 0 && <span>
                                    <Link to={`/write/${item.id}?id=${item.id}`}>手写</Link> 
                                </span>}
                            {toDate(item.update_time)}

 
                            <Link to={`/view/${item.id}?id=${item.id}`}>查看</Link> 

                            </h5>
                        </div>
                    )
                    }
 
                </div>

                </div>
 
        </div>)
    }

}

export { SubjectSelectPage };