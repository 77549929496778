import lottie from 'lottie-web';
import  { useEffect } from "react";
import animationData1 from "../static/a_start.json";

const StartPlay = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.getElementById("lottiestart"),
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: animationData1,
        });
    }, []);

    return <div id='lottiestart' />;
};

export default StartPlay;