
import { http } from '../utils/http'


import React from "react";

import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';


class WhriteKeyBoard extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)


        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)

        this.state = {
            subject: null,
            records: [],
            message: " 请在右边写出您的内容，完成后提交。",
            score: 0
        }
        this.attr = { id: params.id, keyboard: null, "signpad": null, "undoData": [], "editor": null, timer: 0 }

        this.handleQuerySelect = this.handleQuerySelect.bind(this)
        this.loadRecords = this.loadRecords.bind(this)





    }

    componentDidMount() {

        const self = this

        this.loadSubject(this.attr.id);

        //this.loadRecords()

        if (this.attr.keyboard == null) {
            let _char = null;
            let _input = null;
            let _submit_state = 0;
            let _show_state = 0;
            let _seconds = 0;

            // this.attr.keyboard = new Keyboard({
            //     onChange: input => onChange(input),
            //     onKeyPress: button => onKeyPress(button)
            // });


            this.attr.keyboard   = new Keyboard({
                onChange: input => onChange(input),
                onKeyPress: button => onKeyPress(button),
                theme: "hg-theme-default hg-theme-ios",
                layout: {
                  default: [
                    "q w e r t y u i o p {bksp}",
                    "a s d f g h j k l {enter}",
                    "{shift} z x c v b n m , . {shift}",
                    "{alt} {smileys} {space} {altright} {downkeyboard}"
                  ],
                  shift: [
                    "Q W E R T Y U I O P {bksp}",
                    "A S D F G H J K L {enter}",
                    "{shiftactivated} Z X C V B N M , . {shiftactivated}",
                    "{alt} {smileys} {space} {altright} {downkeyboard}"
                  ],
                  alt: [
                    "1 2 3 4 5 6 7 8 9 0 {bksp}",
                    `@ # $ & * ( ) ' " {enter}`,
                    "{shift} % - + = / ; : ! ? {shift}",
                    "{default} {smileys} {space} {back} {downkeyboard}"
                  ],
                  smileys: [
                    "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
                    `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
                    "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
                    "{default} {smileys} {space} {altright} {downkeyboard}"
                  ]
                },
                display: {
                  "{alt}": ".?123",
                  "{smileys}": "\uD83D\uDE03",
                  "{shift}": "⇧",
                  "{shiftactivated}": "⇧",
                  "{enter}": "return",
                  "{bksp}": "⌫",
                  "{altright}": ".?123",
                  "{downkeyboard}": "🞃",
                  "{space}": " ",
                  "{default}": "ABC",
                  "{back}": "⇦"
                }
              });

              function handleLayoutChange(button) {
                let currentLayout = self.attr.keyboard.options.layoutName;
                let layoutName;
              
                switch (button) {
                  case "{shift}":
                  case "{shiftactivated}":
                  case "{default}":
                    layoutName = currentLayout === "default" ? "shift" : "default";
                    break;
              
                  case "{alt}":
                  case "{altright}":
                    layoutName = currentLayout === "alt" ? "default" : "alt";
                    break;
              
                  case "{smileys}":
                    layoutName = currentLayout === "smileys" ? "default" : "smileys";
                    break;
              
                  default:
                    break;
                }
                if (layoutName) {
                    self.attr.keyboard.setOptions({
                      layoutName: layoutName
                    });
                  }
                }
         
            function onChange(input) {
                document.querySelector(".input").value = input;

                console.log("Input changed", input);

                //self.handleQuerySelect(input)

                _input = input;

                _seconds = new Date().getSeconds();

                if (_submit_state == 0) {
                    _submit_state = 1;
                    setTimeout(e => {
                        _submit_state = 0;
                        self.handleQuerySelect(_input);
                    }, 3000)
                }
            }

            function onKeyPress(button) {
                console.log("Button pressed", button);

                /**
                 * If you want to handle the shift and caps lock buttons
                 */
                //if (button === "{shift}" || button === "{lock}") handleShift();

                if (button == "{enter}"  || button === "{ent}" ) {
                    //_char = "<br/>"

                    self.attr.keyboard.setInput(_input + "\n");
                }
                if (button.includes("{") && button.includes("}")) {
                    handleLayoutChange(button);
                  }

            }

            function handleShift() {
                let currentLayout = self.attr.keyboard.options.layoutName;
                let shiftToggle = currentLayout === "default" ? "shift" : "default";

                self.attr.keyboard.setOptions({
                    layoutName: shiftToggle
                });
            }

            document.querySelector(".input").addEventListener('blur', function (event) {
                event.preventDefault();
            });
            document.querySelector(".input").addEventListener('keydown', event => {
                console.log(`User pressed: ${event.key}`);
                event.preventDefault();
                return false;
            });


            function showTextBg() {
                //textBg.innerHTML = _input; 
                if (_input == null || _input.length < 1) {

                } else {

                    //输入完成1秒后， 再显示加..
                    if (new Date().getSeconds() - _seconds >= 1) {


                        if (_show_state == 0) {
                            _show_state = 1;
                            document.querySelector(".input").value = _input + '.';
                        } else {
                            _show_state = 0;
                            document.querySelector(".input").value = _input + '..';
                        }
                    }
                }

                console.log("...")

            }
            //showTextBg();
            window.clearInterval(self.attr.timer);
            //self.attr.timer = setInterval(showTextBg,1000);
            document.getElementById("inputtext").addEventListener('focus', function (event) {
                console.log(event);
            });
        }



    }

    componentWillUnmount() {
        console.log(' clear component.. ');
        window.clearInterval(this.attr.timer);
    }


    loadSubject(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subject?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }


    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/records`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }


    handleQuerySelect(data) {
        const self = this;

        //let data = []

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'data': data })
        };

        self.setState({ message: String('正在识别中..') })
        http.YGet(`/api/keytext`, requestOptions, function (data) {
            var content = String(data.data.message)
            if (content.length < 1) {
                self.setState({ message: String('没有识别到书写的内容，请重新书写') })
            } else {
                console.log(data)
                self.setState({
                    message: String(data.data.message),
                    score: data.data.score
                })
            }

        });



        //var data = []

    }


    render() {
        const { records, message, score, subject } = this.state;
        const self = this;
        console.log(records)
        return (<div className='disable-select'>


            <div className="d-flex flex-column write_pad ">

                {/* 
                <div className='keypad-t'>
                    <div className=" align-items-center t-box">
                        <div className="t-menu ">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>

                        </div>
                        <div className="a-auto t-submit">

                            <button type="button" className="btn btn-primary" onClick={this.handleQuerySelect}>提交</button>

                        </div>
                    </div>
                </div>

                <div className='  keypad-tool'>

                    <div className='keypad-title ' >
                        <div className="col">
                            当前默写:我的房间
                        </div>

                        <div className="col text-end ">
                            <div className='r-score'>{score} 分</div>

                        </div>



                    </div>
                    <div className='keypad-body'>
                        <textarea className="input" rows="5" oninput="this.rows = this.value.split('\n').length"></textarea>
                        <div className="simple-keyboard b">

                        </div> 
                    </div>
                  <div className='pad-text'>{message}</div>  


                </div> */}

                <div className='keypad-t '>
                    <div className=" align-items-center t-box">
                        <div className="t-menu ">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>

                        </div>
                        <div className="a-auto t-submit">
                            {subject && <span className=''>{subject.name} </span>}
                            <span className='r-score2'>{score} 分</span>

                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column keypad-body' >
                    <div className="simple-keyboard-preview">
 
                            {subject && 
                        <>
                            {subject.prompt && <textarea id='inputtext' placeholder="Please use the keyboard below to enter content, thank you!" className="input input-s" readOnly ></textarea>}
                            {!subject.prompt && <textarea id='inputtext' placeholder={subject.contents} className="input input-s" readOnly ></textarea>}
                            
                            </>
                        }
                        {!subject && <textarea id='inputtext' placeholder="Please use the keyboard below to enter content, thank you!" className="input input-s"  readOnly ></textarea>}
                        <div className='pad-prompt2'>
                            {subject && subject.prompt && <div  className='p-2'   >
                                <h5>提示词：</h5>
                                {subject.prompt.replace(/(?:\r\n|\r|\n)/g, "   ,   ")}

                            </div>}
                        </div>
                    </div>

                    <div className="preview2 ">
                        
                    
                        <div className="simple-keyboard b">

                        </div>
                    </div>
                </div>

            </div>

            {/* {records.length > 0 &&
                <div>
                    <ul>
                        {records.map((item, index) =>
                            <li className='query_item' key={index} >
                                {item.result}, {item.create_time}
                            </li>
                        )
                        }
                    </ul>
                </div>
            } */}
        </div>)
    }

}

export { WhriteKeyBoard };