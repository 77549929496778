import React from 'react';
import ReactDOM from 'react-dom/client';


 

import './index.css';
import { HomePage } from './page/home'; 
import { SubjectSelectPage } from './page/subject_select'; 
import { WhriteBoard } from './page/whriteboard'; 
import {WhriteKeyBoard} from './page/whritekeyboard';
import { ListionWhriteBoard } from './page/listionwhrite';
import { SubjectView } from './page/subject_view';
import {Dotask} from './page/dotask';
import { DotaskImage } from './page/dotaskimage';
import {TaskView} from './page/taskview';
import {ChineseTestBoard} from './page/chinesetest';
import {ChineseTestBoard4} from './page/chinesetest4';
import {ChineseTestBoard5} from './page/chinesetest5';
import {EnglishTestBoard} from './page/englishtest';
import {PoetryBoard} from './page/poetrytest';
import {PingYinBoard} from './page/pingyin';

import {EngShoolBoard} from './page/engshool';
import reportWebVitals from './reportWebVitals';

import {ChatsView} from './page/chats';

import { ScoreProductPage } from './page/scoreproduct';

import {DoVoice1} from './page/dovoice1';


import 'bootstrap/dist/css/bootstrap.css';

// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";

import { BrowserRouter,Route,Routes,redirect } from 'react-router-dom';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <SubjectSelectPage />,
//   },
//   {
//     path: "/write/:id",
//     element: <WhriteBoard />,
//   },
//   {
//     path: "/writekey/:id",
//     element: <WhriteKeyBoard />,
//   },
// ]);

// const router =createBrowserRouter([
//   {
//     path: "/",
//     element: <SubjectSelectPage />,
//     loader:({ params }) => {
//       return fakeGetTeam(params.teamId);
//     },
//     children: [
//       {
//         path: "write/:id",
//         element: <WhriteBoard />,
//         loader: eventLoader,
//       },
//     ],
//   },
// ]);

let rootD = document.getElementById('react-root');
rootD.innerHTML = '';

const root = ReactDOM.createRoot(rootD);
root.render(
  //<App />
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  // <React.StrictMode>
  //   <RouterProvider router={router} />
  // </React.StrictMode>
//   <React.StrictMode>
// <RouterProvider router={router} />
// </React.StrictMode>

<BrowserRouter basename="/">
<Routes>
  <Route path="/" Component={HomePage}/> {/* 👈 Renders at /app/ */}
  <Route path="/subject" Component={SubjectSelectPage}/>
  <Route path="/write/:id" Component={WhriteBoard}/>
  <Route path="/dotask/:id" Component={DoVoice1}/>
  <Route path="/dotaskimage/:id" Component={DotaskImage}/>
  <Route path="/taskview/:id" Component={TaskView}/>
  <Route path="/writekey/:id" Component={WhriteKeyBoard}/>
  <Route path="/listionwrite/:id" Component={ListionWhriteBoard}/>
  <Route path="/view/:id" Component={SubjectView}/>
  <Route path="/chinesetest/:id" Component={ChineseTestBoard5}/>
  <Route path="/chinesetest4/:id" Component={ChineseTestBoard4}/>
  <Route path="/engtest/:id" Component={EnglishTestBoard}/>
  <Route path="/engsh/:id" Component={EngShoolBoard}/>
   <Route path="/pingyintest/:id" Component={PingYinBoard}/>
   <Route path="/poetrytest/:id" Component={PoetryBoard}/>
   <Route path='/chat/:id' Component={ChatsView} />
   <Route path='/scoreproduct/:id' Component={ScoreProductPage} />
</Routes>
</BrowserRouter>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
