
import { http } from '../utils/http'
import _ from 'lodash'
import React from "react";
//import Atrament from 'atrament';

//import SmoothSignature from '../utils/smoothsignature'

//import $ from 'jquery';

//import Script from 'react-load-script';
//import '../static/hanzilookup.min.js'

class ChineseTestBoard4 extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)
        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)


        this.state = {
            subject: null,
            records: [],
            message: "",
            result: "",
            promptNum: 3,
            score: -1,
            start: false,
            end: false,
            identiMessage: "",
            submited: -1,
            scriptStatus: 'no',
            progressString: ""
        }
        this.attr = { 
            id: params.id, 
            subject_id: 0,
            resetNum: 3, 
            mtag: 0, 
            iframe: null, 
            iframeclear: null, 
            preword: null, 
            signpad: null, 
            undoData: [], 
            editor: null, 
            checks: [], 
            checks_promt:[],
            checks_id: 0, 
             word: "", 
             single_word: "", 
             sentences: "" }

        this.handleQuerySelect = this.handleQuerySelect.bind(this);
        this.loadRecords = this.loadRecords.bind(this);

        this.getCheckContent = this.getCheckContent.bind(this);
        this.reCheckContent = this.reCheckContent.bind(this);

        this.getPrompt = this.getPrompt.bind(this);

        this.preIdentification = this.preIdentification.bind(this);


    }

    componentDidMount() {

        const self = this
        this.loadSubject(this.attr.id);

        var iframeCon = document.querySelector('#draw_box');

        /*** 		const canvas = document.querySelector('#sketchpad');
            	
                const sketchpad = new Atrament(canvas, {
                  width: 207,
                  height: 300,
                  color: 'orange',
                });  
                ***/

        var iframe = document.createElement('iframe');

        iframe.id = "frame";

        //iframe.style = "display:none;";

        iframe.name = "polling";
        iframe.width = '100%';
        iframe.height = '99%';

        //iframe.src="/file/index.html?w="+window.innerWidth;
        iframe.src = "/file/index.html?w=280";

        iframeCon.appendChild(iframe);

        iframe.onload = function () {

            var iloc = iframe.contentWindow.location;

            var idoc = iframe.contentDocument;

            //setTimeout(function(){

            //var text = idoc.getElementsByTagName('body')[0].textContent;

            //console.log(text);

            //iloc.reload(); //刷新页面,再次获取信息，并且会触发onload函数

            //},2000);
            iframe.contentWindow.callback = function (e) {
                //alert('ok'); 
                self.setState({ identiMessage: e, submited: 2 });
            };

            self.attr.iframeclear = iframe.contentWindow.clearBoard;
        }

        self.attr.iframe = iframe;






    }

    loadSubject(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subjectcheck?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.attr.subject_id = data.data.data.id;
            self.attr.checks = data.data.checks;
            self.attr.checks_promt = data.data.prompt;
            self.attr.mtag = data.data.data.mtag;
            self.setState({
                subject: data.data.data
            })
        })
    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/records`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }

    getCheckContent() {

        const self = this;
        const word = self.attr.checks[self.attr.checks_id];
        const word_prompt = self.attr.checks_promt[self.attr.checks_id];

        self.attr.resetNum = 3;
        self.attr.checks_id++;



        if (self.attr.checks_id > self.attr.checks.length) {
            alert("测试完成。");
            self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', start: false });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'checklength': self.attr.checks.length })
            };
            http.YGet(`/api/getcheckcontentresult`, requestOptions, function (data) {
                console.log(data)

                self.setState({
                    message: data.data.message,
                    score: data.data.data.s_base,
                    end: true
                })
            })

            return;
        }

        //self.attr.editor.bailToMark('first');
        self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', submited: -1 });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'word': word, word_prompt:word_prompt, 'update': 0 })
        };
        http.YGet(`/api/getcheckcontent5`, requestOptions, function (data) {
            console.log(data)
            self.attr.word = data.data.message.w1;
            self.attr.sentences = data.data.message.w3;
            self.setState({
                message: data.data.message.w3,
                score: -1,
                start: true,
                progressString: self.attr.checks_id + "/" + self.attr.checks.length
            })
        })
    }

    reCheckContent() {

        const self = this;
        const word = self.attr.checks[self.attr.checks_id - 1];
        const word_prompt = self.attr.checks_promt[self.attr.checks_id- 1];
        self.attr.resetNum = self.attr.resetNum - 1;

        if (self.attr.resetNum < 0) {
            alert("您已经重新多次测试了，不能重试了。");
            return;
        }

        self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', submited: -1 });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'word': word, word_prompt:word_prompt, 'update': 1 })
        };
        http.YGet(`/api/getcheckcontent5`, requestOptions, function (data) {
            console.log(data)
            self.attr.word = data.data.message.w1;
            self.attr.sentences = data.data.message.w3;
            self.setState({
                message: data.data.message.w3,
                score: -1,
                start: true,
                progressString: self.attr.checks_id + "/" + self.attr.checks.length
            })
        })
    }

    getPrompt() {
        //this.attr.promptNum = this.attr.promptNum - 1;

        if (this.state.promptNum <= 0) {
            alert("您已经提示了多次，不能再提示了。");
            return;
        }
        this.setState({ promptNum: this.state.promptNum - 1 })

        alert("正确答案：" + this.attr.word);

    }

    preIdentification() {
        const self = this;
        let shapes = this.attr.editor.getCurrentPageShapeIds();
        if (shapes.size < 1) {
            window.alert("请在右边写出您的内容，完成后提交。")
            return null;
        }
        console.log(shapes)

    }

    handleQuerySelect() {
        const self = this;
        const message = this.state.identiMessage;


        self.setState({ identiMessage: String(''), submited: -1 })

        this.attr.iframeclear();

        //self.attr.editor.bailToMark('first');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'word': self.attr.word, 'message': message, score: self.state.score })
        };

        self.setState({ message: String('正在识别中..'), score: -1 })
        http.YGet(`/api/submittextresult`, requestOptions, function (data) {
            var content = String(data.data.message)
            if (content.length < 1) {
                self.setState({ message: String('没有识别到书写的内容，请重新书写') })
            } else {
                console.log(data)
                self.setState({
                    submited: 0,
                    result: String(data.data.message),
                    score: data.data.score,
                    message: String(self.attr.sentences),
                    progressString: self.attr.checks_id + "/" + self.attr.checks.length
                })
            }

        });



    }




    render() {
        const { records, message, identiMessage, result, score, subject, promptNum, start, end, submited, progressString } = this.state;
        const progress = parseInt((this.attr.checks_id) / this.attr.checks.length * 100)
        const self = this;
        console.log("progress=" + progress)

        const identifi_none = identiMessage.indexOf('没有') > -1;

        return (<div className='disable-select'>



            {!start &&
                <div className=' d-flex align-items-center  vh-100 justify-content-center'>
                    <div className='text-center'>
                        <h1>{subject && <span className=''>{subject.name} </span>}  </h1>
                        <div className='p-5'>
                            <button type="button" className="btn start-btn" onClick={this.getCheckContent}>开始测试</button>
                        </div>
                    </div>
                </div>
            }


            {end &&
                <div className=' d-flex align-items-center  vh-100 justify-content-center'>
                    <div className='text-center'>
                        <h1>{subject && <span className=''>{message}</span>}  </h1>
                        <div className='p-5'>
                            <button type="button" className="btn start-btn" onClick={e => { this.startGame(); }}>重新测试</button>
                        </div>
                    </div>
                </div>
            }



            <div className={` flex-column write_pad default-none ${start ? 'd-flex' : ''} show-${start}${submited} end-${end}`} >


                <div className='head-tool-w'>

                    <div className="d-flex justify-content-between t-up">
                        <div className="col1">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>结束</button>
                        </div>
                        <div className="col2">



                            {subject && <span className=''>{subject.name} </span>}

                        </div>
                        <div className="col3">

                            {message.length < 1 &&
                                <span><button type="button" className="btn btn-secondary mr-2" onClick={this.getCheckContent}>开始测试</button> &nbsp;</span>
                            }


                            {score > -1 &&
                                <button type="button" className="btn  btn-info " onClick={this.getCheckContent}>下一题</button>
                            }


                        </div>
                    </div>


                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar  bg-info" style={{ width: `${progress}%` }} >{progressString} {Math.round(progress)}%</div>
                    </div>
                </div>

                <div className='d-flex  flex-column justify-content-between pad-w'>

                    <div className="   pad-w-up ">
                        {/* <div className='pad-title ' >


                            <div className="row align-items-start">
                                <div className="col">
                                    当前默写:我的房间
                                </div>

                                <div className="col text-end ">
                                    <div className='r-score'>{score} 分</div>
                                    
                                </div>
                            </div>


                            
                        </div> */}

                        {message.length > 0 &&
                            <>

                                <div className='sent ' >
                                    <div className='sent-t'>写出下列句子中拼音对应的汉字：
                                        {message.length > 1 &&
                                            <a className=" mr-2" onClick={this.getPrompt}>提示({promptNum})</a>
                                        }</div>
                                    <div className='sent-b'>  


                                    <p dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br>") }} />
                                    </div>
                                </div>
                                {score < 50 && submited != 2 &&
                                    <div className='text-center p-3' >
                                        <button type="button" className="btn btn-light " onClick={this.reCheckContent}>重新出题({this.attr.resetNum})</button>
                                    </div>

                                }


                            </>


                        }
                        {message.length < 1 &&
                            <div className=' pt-5 text-center' >提示：点击右上角【开始测试】</div>
                        }
                        {submited == 0 &&

                            <div className='result text-center'>
                                <div>我的答案：</div>
                                <h1 className=''>{result}</h1>
                                <span className='r-score2'>本题得分{score} 分</span>&nbsp;

                                {score < 100 && <span className='r-score2'>字体不标准，请继续努力</span>}
                                <div className=''>正确答案是:{this.attr.word}</div>

                                <div className='text-center pt-3' >
                                    <button type="button" className="btn btn-light " onClick={this.getCheckContent}>下一题</button>

                                </div>
                            </div>
                        }




                    </div>
                    <div className="  pad-w-down ">

                        <div className="t-w-box5">
                            {identiMessage && submited == 2 &&
                                <div className='text-center p-3' >
                                    <h2>{identiMessage}</h2>
                                    <button type="button" className="btn btn-primary" onClick={this.handleQuerySelect}>提交答案</button>

                                </div>

                            }
                            <div className={`han default-none show-${start}${submited}`} id="draw_box" >


                            </div>

                        </div>


                    </div>
                </div>
            </div>

            {/* {records.length > 0 &&
                <div>
                    <ul>
                        {records.map((item, index) =>
                            <li className='query_item' key={index} >
                                {item.result}, {item.create_time}
                            </li>
                        )
                        }
                    </ul>
                </div>
            } */}




        </div>)
    }

}

export { ChineseTestBoard4 as ChineseTestBoard5 };