 
import { authHeader } from './auth-header';
import axios from 'axios';

export const http = {
    YGet,
    handleResponse 
};


function YGet(apiUrl, requestOptions, callback){ 
 
    //用户传入的接口配置参数 
    const params = {} 
    const timeout = 120000 

    /**
     * 返回的Promise对象含有then、catch方法
     */
    return new Promise(function (resolve, reject) {
        axios({
            url: apiUrl,
            method: requestOptions.method,
            params: params,
            data: requestOptions.body,
            timeout: timeout,
            headers: { ...authHeader(), 'Content-Type': 'application/json' }

        }).then(function (response) {  
            //console.log( response)
            callback( response )
             
        }).catch(function (error) { 
            if (error.response) {
                // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
                console.log(error.response.data);
                console.log(error.response.status);
            }
        })
    })
 
}


function handleResponse(response) { 
    //console.log('response='+JSON.stringify(response)) 
    return Promise.resolve(JSON.stringify(response.data)).then(text=>{
        const data = text && JSON.parse(text); 
        //console.log(data)  
        return data;
    })
 
}