
import { http } from '../utils/http'
import React from "react";
import Keyboard from 'simple-keyboard';

import StarsPlay from '../components/lottie_stars';

import 'simple-keyboard/build/css/index.css';


class EngShoolBoard extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)
        console.log("this.props.match.params.data=" + this.props.match)
        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)

        this.state = {
            subject: null,
            records: [],
            message: " 请点击右上角开始测试。",
            messagePrompt: "",
            result: "",
            identiMessage: '',
            start: false,
            end: false,
            promptNum: 5,
            submited: -1,
            loadingResult: -1,

            score: -1
        }
        this.attr = {
            id: params.id, keyboard: null, resetNum: 3, "signpad": null, "undoData": [], "editor": null, timer: 0, subject_id: -1,
            checks: [],
            checks_eng: [],
            checks_id: 0,
            start_time: null,
            end_time: null,
            mtag: -1
        }

        this.handleQuerySelect = this.handleQuerySelect.bind(this)
        this.loadRecords = this.loadRecords.bind(this)
        this.getCheckContent = this.getCheckContent.bind(this);
        this.getPrompt = this.getPrompt.bind(this);
        this.reCheckContent = this.reCheckContent.bind(this);

    }

    componentDidMount() {

        const self = this

        this.loadSubject(this.attr.id, () => { });



        if (this.attr.keyboard == null) {
            let _char = null;
            let _input = null;
            let _submit_state = 0;
            let _show_state = 0;
            let _seconds = 0;

            this.attr.keyboard = new Keyboard({
                onChange: input => onChange(input),
                onKeyPress: button => onKeyPress(button),
                mergeDisplay: true,
                layoutName: "default",
                layout: {
                    default: [
                        "q w e r t y u i o p",
                        "a s d f g h j k l",
                        "{shift} z x c v b n m {backspace}",
                        "{numbers} {space} {ent}"
                    ],
                    shift: [
                        "Q W E R T Y U I O P",
                        "A S D F G H J K L",
                        "{shift} Z X C V B N M {backspace}",
                        "{numbers} {space} {ent}"
                    ],
                    alt: [
                        "1 2 3 4 5 6 7 8 9 0 {bksp}",
                        `@ , . ( ) ' " {enter}`,
                        "{shift} % - + = / ; : ! ? {shift}",
                        "{default} {smileys} {space} {back} {downkeyboard}"
                    ],
                    numbers: ["1 2 3 4 5 6 7 8 9 0", `@ , . * ( ) ' " `, "% - + = / ; : ! ? {backspace}", "{abc} {back}"]
                },
                display: {
                    "{numbers}": ".?123",
                    "{ent}": "return",
                    "{escape}": "esc ⎋",
                    "{tab}": "tab ⇥",
                    "{backspace}": "⌫",
                    "{capslock}": "caps lock ⇪",
                    "{shift}": "⇧",
                    "{controlleft}": "ctrl ⌃",
                    "{controlright}": "ctrl ⌃",
                    "{altleft}": "alt ⌥",
                    "{altright}": "alt ⌥",
                    "{metaleft}": "cmd ⌘",
                    "{metaright}": "cmd ⌘",
                    "{abc}": "ABC"
                }
            });

            /**
             * Update simple-keyboard when input is changed directly
             */
            document.querySelector(".input").addEventListener("input", event => {
                self.attr.keyboard.setInput(event.target.value);
            });

            console.log(self.attr.keyboard);

            function onChange(input) {
                //document.querySelector(".input").value = input;
                console.log("Input changed", input);
                self.setState({ identiMessage: input })

            }

            function onKeyPress(button) {
                console.log("Button pressed", button);

                /**
                 * If you want to handle the shift and caps lock buttons
                 */
                if (button === "{shift}" || button === "{lock}") handleShift();
                if (button === "{numbers}" || button === "{abc}") handleNumbers();

                if (button.toUpperCase() === button) {
                    handleShift();
                }
            }

            function handleShift() {
                let currentLayout = self.attr.keyboard.options.layoutName;
                let shiftToggle = currentLayout === "default" ? "shift" : "default";

                self.attr.keyboard.setOptions({
                    layoutName: shiftToggle
                });
            }

            function handleNumbers() {
                let currentLayout = self.attr.keyboard.options.layoutName;
                let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";

                self.attr.keyboard.setOptions({
                    layoutName: numbersToggle
                });
            }
        }

    }

    componentWillUnmount() {
        console.log(' clear component.. ');
        window.clearInterval(this.attr.timer);
    }


    startGame() {
        const self = this;
        this.loadSubject(this.attr.id, () => {
            self.getCheckContent();
        });
    }


    loadSubject(id, c) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subjectengshoolcheck?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.attr.subject_id = data.data.data.id;
            self.attr.checks = data.data.checks;
            self.attr.checks_eng = data.data.eng;

            self.attr.mtag = data.data.data.mtag;

            self.attr.start_time = new Date().getTime();
            self.setState({
                subject: data.data.data
            });
            document.title = data.data.data.name;
            c();
        })
    }


    getPrompt() {
        //this.attr.promptNum = this.attr.promptNum - 1;

        if (this.state.promptNum <= 0) {
            alert("您已经提示了多次，不能再提示了。");
            return;
        }
        this.setState({ promptNum: this.state.promptNum - 1 })

        alert("正确答案：" + this.attr.word);

    }
    getCheckContent() {

        const self = this;
        const word = self.attr.checks[self.attr.checks_id];
        const word_eng = self.attr.checks_eng[self.attr.checks_id];
        console.log("word=" + word);

        self.attr.resetNum = 3;
        self.attr.checks_id++;


        this.attr.keyboard.clearInput();


        document.querySelector('#inputtext').value = ''


        //self.attr.keyboard.setInput("III");

        if (self.attr.checks_id > self.attr.checks.length) {
            alert("测试完成。");
            self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', messagePrompt: '', submited: -1, start: false });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'checklength': self.attr.checks.length })
            };
            http.YGet(`/api/getcheckcontentresult`, requestOptions, function (data) {
                console.log(data)

                self.setState({
                    message: data.data.message,
                    score: data.data.data.s_base,
                    start: true,
                    end: true
                })
            })

            return;
        }

        self.attr.word = word_eng;
        self.attr.sentences = word;
        self.setState({
            message: word,
            identiMessage: '',
            submited: -1,
            messagePrompt: '',
            score: -1,
            start: true,
            progressString: self.attr.checks_id + "/" + self.attr.checks.length
        })

    }


    reCheckContent() {

        this.setState({ score: -1, submited: -1 });

    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/records`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }


    handleQuerySelect() {
        const self = this;
        const message = this.state.identiMessage;


        self.setState({ identiMessage: String(''), submited: -1, score: -1, loadingResult: 0 })
        document.querySelector('#inputtext').value = ''


        //self.attr.editor.bailToMark('first');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'tid': 7, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'word': self.attr.word, 'message': message, score: self.state.score })
        };

        //self.setState({ message: String('正在识别中..'), score: -1 })
        http.YGet(`/api/submittextresult`, requestOptions, function (data) {
            var content = String(data.data.message)
            if (content.length < 1) {
                self.setState({ message: String('没有识别到书写的内容，请重新书写') })
            } else {
                console.log(data)
                self.setState({
                    submited: 0,
                    loadingResult: -1,
                    result: String(data.data.message),
                    score: data.data.score,
                    message: String(self.attr.sentences),
                    progressString: self.attr.checks_id + "/" + self.attr.checks.length
                })
            }

        });
    }


    render() {
        const { records, message, messagePrompt, progressString, identiMessage, promptNum, result, score, subject, start, end, submited, loadingResult } = this.state;
        const progress = parseInt((this.attr.checks_id) / this.attr.checks.length * 100)
        const self = this;
        console.log(records)
        return (<div className='disable-select'>



            {!start &&
                <div className=' d-flex align-items-center  vh-100 justify-content-center'>
                    <div className='text-center'>
                        <h1>{subject && <span className=''>{subject.name} </span>}  </h1>
                        <div className='p-5'>
                            <button type="button" className="btn start-btn" onClick={this.getCheckContent}>开始测试</button>
                        </div>
                    </div>
                </div>
            }


            {end &&
                <div className=' d-flex align-items-center  vh-100 justify-content-center'>
                    <div className='text-center'>
                        <h1>{subject && <span className=''>{message}</span>}  </h1>
                        <div className='p-5'>
                            <button type="button" className="btn start-btn" onClick={e => { this.startGame(); }}>重新测试</button>
                        </div>
                    </div>
                </div>
            }
            <div className={` flex-column write_pad default-none ${start ? 'd-flex' : ''} show-${start}${submited} end-${end}`} >


                <div className='head-tool-w'>

                    <div className="d-flex justify-content-between t-up">
                        <div className="col1">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>结束</button>
                        </div>
                        <div className="col2">



                            {subject && <span className=''>{subject.name} </span>}

                        </div>
                        <div className="col3">

                            {start && message.length > 1 && submited == 0 &&


                                <div className='  ' >
                                    <button type="button" className="btn btn-light " onClick={this.getCheckContent}>下一题</button>
                                </div>

                            }
                            {!start &&
                                <span><button type="button" className="btn btn-secondary mr-2" onClick={this.getCheckContent}>开始测试</button> &nbsp;</span>
                            }

                        </div>
                    </div>


                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar  bg-info" style={{ width: `${progress}%` }} >{progressString} {Math.round(progress)}%</div>
                    </div>
                </div>

                <div className='d-flex flex-column keypad-body' >
                    <div className="simple-keyboard-preview">
                        <div className='pad-prompt2'>
                            {submited != 0 && <>

                                {start && message.length > 1 &&
                                    <div className=' p-3'>
                                        根据下列中文，写出英文：

                                        {start && message.length > 1 &&
                                            <a className=" mr-2" onClick={this.getPrompt}>提示({promptNum})</a>
                                        }
                                    </div>
                                }

                                <div className='sent-b p-3'><h1 className='text-center p-2'>{message}</h1>

                                    {start && <div>

                                        <div className='text-center'> <audio controls src={`http://dict.youdao.com/dictvoice?type=0&audio=${self.attr.word}`}   ></audio></div>

                                        {/**提示：{messagePrompt} **/}

                                        <div className='text-center p-1  ' >
                                            <button type="button" className="btn btn-light btn-c " onClick={this.reCheckContent}>刷新</button>
                                        </div>


                                    </div>}
                                </div>
                                {identiMessage.length > 0 &&
                                    <div className=' p-3'>

                                        <div id='inputResult' className="p-3 text-center">{identiMessage}</div>

                                        <div className="p-3 text-center"><button type="button" className="btn btn-primary" onClick={this.handleQuerySelect} > 提交 </button> </div>

                                    </div>

                                }

                                {loadingResult == 0 &&
                                    <div className="text-center">正在加载..</div>
                                }

                            </>
                            }

                            {submited == 0 &&

                                <div className='result d-flex flex-column  result text-center align-items-center  vh-100 '>
                                    {score >= 95 && <div style={{ width: '200px' }} className='' ><StarsPlay /></div>}
                                    <span className='r-score2'>本题得分{score} 分</span>&nbsp;
                                    {score < 95 && <span className='r-score2'>加油，请继续努力</span>}
									<div className='pt-2'>我的答案：</div>
                                    <h3 className=''>{result}</h3>

                                    <div className=''>正确答案是:{this.attr.word}</div>

                                    <div className='text-center pt-3' >
                                        <button type="button" className="btn btn-light " onClick={this.getCheckContent}>下一题</button>

                                    </div>
                                </div>
                            }


                        </div>
                        {subject &&
                            <>
                                <textarea id='inputtext' placeholder={subject.contents} className="input input-s default-none" readOnly ></textarea>

                            </>
                        }
                        {!subject && <textarea id='inputtext' placeholder="" className="input input-s default-none" readOnly ></textarea>}

                    </div>

                    <div className={`preview2 default-none show-${start}${submited}`}>
                        <div className="simple-keyboard b">

                        </div>
                    </div>
                </div>

            </div>

            {/* {records.length > 0 &&
                <div>
                    <ul>
                        {records.map((item, index) =>
                            <li className='query_item' key={index} >
                                {item.result}, {item.create_time}
                            </li>
                        )
                        }
                    </ul>
                </div>
            } */}
        </div>)
    }

}

export { EngShoolBoard };