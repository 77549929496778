import lottie from 'lottie-web';
import  { useEffect } from "react";
import animationData from "../static/animation.json";
const StarsPlay = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.getElementById("lottiestar"),
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: animationData,
        });
    }, []);

    return <div id='lottiestar' />;
};
export default StarsPlay;