
import { http } from '../utils/http'
import _ from 'lodash'
import React, { useRef } from "react";
import Webcam from "react-webcam";



function base64toBlob(base64, type = 'application/octet-stream') {
    const bstr = atob(base64);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type });
  }
  
  

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_USER
};

const WebcamCapture = ({ id, captureCallback }) => {

    console.log("id=" + id)
    const webcamRef = React.useRef(null);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

    const handleClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();

    

            // const img = document.createElement("img");
            // img.src = imageSrc; 
            // document.getElementById("imageShow").appendChild(img);
            document.getElementById("imageShow").style.display = "block";
            document.getElementById("imgTag").src = imageSrc;
            console.log(imageSrc)
            document.getElementById("WebcamDiv").style.display = "none";

            captureCallback( imageSrc );

            // console.log("imageSrc.length", imageSrc.length)
            // const requestOptions = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ 'id': id, 'data': imageSrc })
            // };

            // //self.setState({ message: String('正在识别中..') })
            // http.YGet(`/api/capture`, requestOptions, function (data) {
            //     var content = String(data.data.message)
            //     if (content.length < 1) {
            //         //self.setState({ message: String('没有识别到书写的内容，请重新书写') })
            //     } else {
            //         console.log(data)
            //         // self.setState({
            //         //     message: String(data.data.message),
            //         //     score:data.data.score
            //         // })
            //     }

            // });



        },
        [webcamRef]
    );
    return (
        <div className='' id="WebcamDiv">

            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                videoConstraints={{
                    ...videoConstraints,
                    facingMode
                }}
            />
            <div>
            <button onClick={handleClick}>切换前后摄像头</button> <button onClick={capture}>拍照</button>
            </div>
            
        </div>
    );
};



class DotaskImage extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)

        this.state = {
            subject: null,
            records: [],
            message: " 拍照后，会自动提交。",
            score: 0
        }
        this.attr = { id: params.id, "signpad": null, "undoData": [], "editor": null ,"imageSrc":null}

        this.loadTask = this.loadTask.bind(this);
        this.resetCama = this.resetCama.bind(this);
        this.captureCallback = this.captureCallback.bind(this);
        this.submitCapture = this.submitCapture.bind(this);
    }

    componentDidMount() {

        const self = this

        this.loadTask(this.attr.id);
    }

    loadTask(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/task?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }

    resetCama() {
        document.getElementById("imageShow").style.display = "none";
        document.getElementById("imgTag").src = "";
        document.getElementById("WebcamDiv").style.display = "block";
    }

    captureCallback(imageSrc){
        this.attr.imageSrc = imageSrc;
    }

    submitCapture(){

        let self = this;


        document.getElementById("imageShow").style.display = "none"; 
        document.getElementById("WebcamDiv").style.display = "none";
        self.setState({message:'正在上传，请稍后...'});

        let blob = base64toBlob( this.attr.imageSrc.split(',')[1] ,"image/jpeg")
        var form = new FormData();
        form.append("upfile", blob, "recorder.jpg"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3 
        form.append("id", this.attr.id);
        //直接用ajax上传
        var xhr = new XMLHttpRequest();
        xhr.open("POST", `/api/capture`);//这个假地址在控制台network中能看到请求数据和格式，请求结果无关紧要
        xhr.onreadystatechange = function () {


            if (xhr.readyState == 4) {


                if (xhr.status == 200) {

                    var json = JSON.parse(xhr.responseText);
                    //console.log(xhr.responseText);
                    console.log('uploaded successfully!');

                    self.setState({message: "上传成功，分析的结果为："+json.message});
           
                } else {
                    alert("上传失败，请联系开发人员处理，感谢！");
                }
                //alert(xhr.status == 200 ? "上传成功" : "保存失败，请联系开发人员处理，感谢！");
            }
        }
        xhr.send(form);

    }

    render() {
        const { records, message, score, subject } = this.state;
        const self = this;
        console.log(records)
        return (<div className=''>


            <div className='text-right'>
                <button type="button" className="btn btn-primary" onClick={e => { window.history.back() }}>返回</button>
                {message}
            </div>
            <div id="imageShow" style={{ display: 'none' }}>
                <button type="button" className="btn btn-secondary" onClick={e => { this.resetCama() }}>重新拍照</button>
                <button type="button" className="btn btn-secondary" onClick={e => { this.submitCapture() }}>确认提交作业</button>
                <img id="imgTag" src=""></img>

            </div>
            <WebcamCapture id={this.attr.id} captureCallback={this.captureCallback}/>

        </div>)
    }

}

export { DotaskImage };