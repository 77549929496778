
import { http } from '../utils/http'
import _ from 'lodash'
import React from "react";


class TaskView extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)




        this.state = {
            task: null,
            records: [],
            message: " 请在右边写出您的内容，完成后提交。",
            score: 0
        }
        this.attr = { id: params.id, "signpad": null, "undoData": [], "editor": null }

        this.handleQuerySelect = this.handleQuerySelect.bind(this)
        this.loadRecords = this.loadRecords.bind(this)



    }

    componentDidMount() {

        const self = this
        this.loadRecords()
        this.loadTask(this.attr.id);
    }

    loadTask(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/task?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                task: data.data.data
            })
        })
    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id })
        };
        http.YGet(`/api/taskrecords?id=` + self.attr.id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }


    handleQuerySelect() {
        const self = this;
    
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id })
        };
        http.YGet(`/api/resettask?id=` + self.attr.id, requestOptions, function (data) {
            console.log(data)
    
            window.history.back() 
        })


    }


    render() {
        const { records, message, score, task } = this.state;
        const self = this;
        console.log(records)
        return (<div className='disable-select'>


            <div className=" write_pad ">



                <div className='page-header-tool'>
                    <div className=" align-items-center t-box">
                        <div className="t-menu ">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>

                        </div>
                        <div className="a-auto t-submit">

                            <button type="button" className="btn btn-primary" onClick={this.handleQuerySelect}>退回重做</button>

                        </div>
                    </div>
                </div>

                <div className=' '>
                    <div className="   ">
                        {records && records.length > 0 &&
                            <div>
                                <ul>
                                    {records.map((item, index) =>
                                        <li className='query_item' key={index} >
                                            {item.create_time}

                                            {item.type == 'img' && <a href={item.link} target='_blank'>查看图片</a>}
                                            {item.type === 'audio' && <span>  <audio controls src={item.link}></audio></span>}
                                        </li>
                                    )
                                    }
                                </ul>
                            </div>
                        }




                    </div>

                </div>
            </div>


        </div>)
    }

}

export { TaskView };