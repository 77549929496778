
import { http } from '../utils/http'
import _ from 'lodash'
import React from "react";
 
import StarsPlay from '../components/lottie_stars';
import StartPlay from '../components/lottie_start';

import SmoothSignature from '../utils/smoothsignature'
import HanziLookup from '../utils/hanzilookup'

//import $ from 'jquery';

//import Script from 'react-load-script';
//import '../static/hanzilookup.min.js'

//显示米字格的文字
function PlaidSvg({ item, index, space, word, windex }) {
    return (
        <>
            {space &&
                <svg height="36" width="36" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0" y="0" width="36" height="36" stroke="#ff4242" fill="white" strokeWidth="1"></rect>
                    <polyline points="1 18,36 18" stroke="#ff4242" strokeWidth="0.5" strokeDasharray="1 2" />
                    <polyline points="18 1,18 36" stroke="#ff4242" strokeWidth="0.5" strokeDasharray="1 2" />
                    <text x="4" y="28" fontSize="28" >{word}</text>

                    {windex == index &&
                        <>
                            {/* <polyline points="0 0,36 36" stroke="#ff4242" strokeWidth="0.5" strokeDasharray="1 2" />
                        <polyline points="0 36,36 0" stroke="#ff4242" strokeWidth="0.5" strokeDasharray="1 2" /> */}
                            <polygon points="10,35 26,35 18,30" fill="#ff4242" stroke="#ff4242" strokeWidth="0.5" />
                        </>
                    }
                </svg>
            }
            {!space &&
                <svg height="36" width="36" xmlns="http://www.w3.org/2000/svg">
                    <text x="4" y="28" fontSize="28" >{item}</text>
                </svg>
            }
        </>

    )
}
 



class PoetryBoard extends React.Component {

    constructor(props) {
        super(props);
        //console.log(this.props.match)
        //console.log(this.props)
        //console.log("this.props.match.params.data=" + this.props.match)

        //分析参数
        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        //console.log(params)


        this.state = {
            subject: null,
            records: [],
            message: "",
            result: "",
            promptNum: 3,
            score: -1,
            start: false,
            end: false,
            identiMessage: "",
            submited: -1,
            scriptStatus: 'no',
            showTexts: [],
            showTexts2: [],
            progressString: ""
        }
        this.attr = {
            id: params.id,
            subject_id: 0,
            subject_con: null,
            resetNum: 3,
            mtag: 0,
            timer: 0, //定时器，防止过快识别
            signature: null,  //书写面板对象
            checks: [],
            checks_id: 0,
            checkLine: '',
            checkLineIndex: 0,
            loadCompleted: 0,
            whriteWords: [],
            whriteIndex: 0,
            word: "",   //当前默写的 汉字
            sentences: "" //例句
        }

        this.handleQuerySelect = this.handleQuerySelect.bind(this);
        this.loadRecords = this.loadRecords.bind(this);

        this.getCheckContent = this.getCheckContent.bind(this);
        this.reCheckContent = this.reCheckContent.bind(this);

        this.getPrompt = this.getPrompt.bind(this);

        this.clearBoard = this.clearBoard.bind(this);
        this.undoBoard = this.undoBoard.bind(this);

        this.showResults = this.showResults.bind(this);
        this.showResults2 = this.showResults2.bind(this);
        this.setIdentiMessage = this.setIdentiMessage.bind(this);
        this.lookup = this.lookup.bind(this);
        this.selectText = this.selectText.bind(this);
        this.startGame = this.startGame.bind(this);

        this.selectInputWord = this.selectInputWord.bind(this);

    }

    componentDidMount() {

        const self = this
        this.loadSubject(this.attr.id, () => { });


        const canvas = document.getElementById("canvas");
        const options = {
            width: 320,//Math.min(window.innerWidth, 1000),
            height: 320,//600
            minWidth: 4,
            maxWidth: 12,
            // color: '#1890ff',
            //bgColor: '#f6f6f6'
            onEnd: function () {
                clearTimeout(self.attr.timer);
                self.attr.timer = setTimeout(self.lookup, 500);
            }
        };

        //初始化书写面板
        this.attr.signature = new SmoothSignature(canvas, options);

        //识别库
        HanziLookup.init("mmah", "/file/mmah.json", function (res) {
            //alert('mmah ok'+res) 
            self.attr.loadCompleted = self.attr.loadCompleted + 1;
            self.setState({ end: false });

        });
        HanziLookup.init("orig", "/file/orig.json", function (res) {
            //alert('orig ok'+res) 
            self.attr.loadCompleted = self.attr.loadCompleted + 1;
            self.setState({ end: false });
        });


    }

    loadSubject(id, c) {


        const self = this;
        self.setState({ subject: null });
        self.attr.checks_id = 0;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subjectpoetrycheck?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.attr.subject_id = data.data.data.id;
            self.attr.checks = data.data.checks;
            self.attr.mtag = data.data.data.mtag;
            self.setState({
                subject: data.data.data,
                end: false
            });
            c();
        })
    }

    startGame() {
        const self = this;
        this.loadSubject(this.attr.id, () => {
            self.getCheckContent();
        });
    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/records`, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }

    getCheckContent() {

        const self = this;
        const word = self.attr.checks[self.attr.checks_id];
        self.attr.subject_con = self.attr.checks[self.attr.checks_id];
        self.attr.resetNum = 3;
        self.attr.checks_id++;



        if (self.attr.checks_id > self.attr.checks.length) {
            alert("测试完成。");

            //这里设置为空数组，防止后面用到报错
            this.attr.subject_con = { con: [] };
            self.attr.checkLineIndex = 0;
            self.attr.checkLine = '';

            self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', start: false });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'checklength': self.attr.checks.length })
            };
            http.YGet(`/api/getcheckcontentresult`, requestOptions, function (data) {
                console.log(data)

                self.setState({
                    message: data.data.message,
                    score: data.data.data.s_base,
                    end: true
                });


            })

            return;
        }


        console.log("self.attr.subject_con=" + self.attr.subject_con['con'])
        self.attr.checkLineIndex = Math.floor(Math.random() * self.attr.subject_con['con'].length)
        self.attr.checkLine = self.attr.subject_con['con'][self.attr.checkLineIndex];

        console.log(" checkLine=" + self.attr.checkLine)



        self.setState({ start: true, message: self.attr.subject_con['title'], submited: -1 })


        this.attr.whriteWords = [];
        this.attr.whriteIndex = 0;


        // self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', submited: -1 });
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'word': word, 'update': 0 })
        // };
        // http.YGet(`/api/getcheckcontent`, requestOptions, function (data) {
        //     console.log(data)
        //     self.attr.word = data.data.message.w1;
        //     self.attr.sentences = data.data.message.w3;
        //     self.setState({
        //         message: data.data.message.w3,
        //         score: -1,
        //         start: true,
        //         progressString: self.attr.checks_id + "/" + self.attr.checks.length
        //     })
        // })
    }

    reCheckContent() {

        const self = this;
        //const word = self.attr.checks[self.attr.checks_id - 1];
        self.attr.resetNum = self.attr.resetNum - 1;

        if (self.attr.resetNum < 0) {
            alert("您已经重新多次测试了，不能重试了。");
            return;
        }

        self.setState({ message: String('正加载中..'), score: -1, identiMessage: '', submited: -1 });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'word': self.attr.word, 'update': 1 })
        };
        http.YGet(`/api/getcheckcontent`, requestOptions, function (data) {
            console.log(data)
            self.attr.word = data.data.message.w1;
            self.attr.sentences = data.data.message.w3;
            self.setState({
                message: data.data.message.w3,
                score: -1,
                start: true,
                progressString: self.attr.checks_id + "/" + self.attr.checks.length
            })
        })
    }

    getPrompt() {
        //this.attr.promptNum = this.attr.promptNum - 1;

        if (this.state.promptNum <= 0) {
            alert("您已经提示了多次，不能再提示了。");
            return;
        }
        this.setState({ promptNum: this.state.promptNum - 1 })
        alert("正确答案：" + this.attr.checkLine);

    }

    selectInputWord(b) {

        if (b < this.attr.whriteWords.length) {
            console.log("b=", b)
            this.attr.whriteIndex = b;
            this.clearBoard();
            this.setState({ identiMessage: '' })
        }

    }
    handleQuerySelect(wp) {
        //console.log("wp=",wp)

        //wp 有时候传进来的是字，所以这里判断是否为字符
        const wi = typeof wp == 'string' ? wp : this.state.identiMessage;
        //当前的书写位置小于 已写出文字的长度时， 就是只修改
        if (this.attr.whriteIndex < this.attr.whriteWords.length) {
            this.attr.whriteWords[this.attr.whriteIndex] = wi;
            this.attr.whriteIndex = this.attr.whriteWords.length
        } else {

            if (this.attr.whriteWords.length < this.attr.checkLine.length) {
                this.attr.whriteWords.push(wi);
                this.attr.whriteIndex++;
            }
        }


        this.clearBoard();
        console.log(this.attr.whriteWords)

        //前
        if (this.attr.whriteWords.length < this.attr.checkLine.length) {
            return;
        }


        const self = this;
        //const message = this.state.identiMessage;


        self.setState({ identiMessage: String(''), submited: -1, message: String('正在识别中..'), score: -1 })
        this.clearBoard();


        //self.attr.editor.bailToMark('first');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id, 'subject_id': self.attr.subject_id, 'mtag': self.attr.mtag, 'word': this.attr.checkLine, 'message': this.attr.whriteWords.join(''), score: self.state.score })
        };


        http.YGet(`/api/submittextresult`, requestOptions, function (data) {
            var content = String(data.data.message)
            if (content.length < 1) {
                self.setState({ message: String('没有识别到书写的内容，请重新书写') })
            } else {
                //console.log(data)
                self.setState({
                    submited: 0,
                    result: String(data.data.message),
                    score: data.data.score,
                    message: String(self.attr.subject_con['title']),
                    progressString: self.attr.checks_id + "/" + self.attr.checks.length
                })
            }

        });



    }

    lookup() {

        //防止误写， 多出一个字
        if (this.attr.whriteWords.length >= this.attr.checkLine.length) {
            return;
        }
        let self = this;

        var raw = self.attr.signature.cloneStrokes();
        //console.log("lookup run ..");
        var analyzedChar = new HanziLookup.AnalyzedCharacter(raw);
        // Look up with original HanziLookup data
        var matcherOrig = new HanziLookup.Matcher("orig");
        matcherOrig.match(analyzedChar, 12, function (matches) {
            //console.log(matches)
            self.showResults(matches);

        });
        // Look up with MMAH data
        var matcherMMAH = new HanziLookup.Matcher("mmah");
        matcherMMAH.match(analyzedChar, 12, function (matches) {
            self.showResults2(matches);
        });
    }
    clearBoard() {
        this.attr.signature.clear();
        this.setState({ showTexts: [], showTexts2: [], identiMessage: '' });
        this.lookup();
    }

    undoBoard() {
        this.attr.signature.undo();
        //this.setState({ showTexts: texts });
        this.lookup();
    }

    showResults(matches) {
        let texts = [];
        for (var i = 0; i != Math.min(15, matches.length); ++i) {
            //console.log("<span onclick='sw(this.innerText)' >" + matches[i].character + "</span>");
            texts.push(matches[i].character);
        }

        if (texts.length > 0) {
            //    this.setState({ showTexts: texts, identiMessage: texts[0], submited: 2 });
            //} else {
            this.setState({ showTexts: texts });
        }
    }

    showResults2(matches) {
        let texts = [];
        for (var i = 0; i != Math.min(15, matches.length); ++i) {
            //console.log("<span onclick='sw(this.innerText)' >" + matches[i].character + "</span>");
            texts.push(matches[i].character);
        }
        //this.setState({ showTexts2: texts });

        if (texts.length > 0) {
            this.setState({ showTexts2: texts, identiMessage: texts[0], submited: 2 });
            //} else {
            //    this.setState({ showTexts: texts });

            //console.log("texts[0]=", texts[0], this.attr.checkLine[this.attr.whriteIndex])
            //自动判断是否写对，提交
            if (texts[0] == this.attr.checkLine[this.attr.whriteIndex]) {
                this.handleQuerySelect(texts[0])
                //console.log("texts[0]=", texts[0], this.attr.checkLine[this.attr.whriteIndex])
            }




        }


    }

    setIdentiMessage(t) {
        this.setState({ identiMessage: t, submited: 2 });
    }

    selectText(e) {
        this.setState({ identiMessage: e.target.innerText, submited: 2 });
    }

    render() {
        const { records, message, identiMessage, result, score, subject, promptNum, start, end, submited, progressString, showTexts, showTexts2 } = this.state;
        const progress = parseInt((this.attr.checks_id) / this.attr.checks.length * 100)
        const self = this;
        //console.log("progress=" + progress)

        const identifi_none = identiMessage.indexOf('没有') > -1;
        const show_pad = message.length > 0 ? 1 : 0;

        return (


            <div className='disable-select '>

                {message.length < 1 &&
                    <div className=' d-flex align-items-center  vh-100 justify-content-center'>

                        {self.attr.loadCompleted < 2 &&
                            <div className='text-center'>
                                <div style={{ width: '300px', height: '300px' }}><StartPlay /></div>
                                <div className='pt-5'>
                                    <button type="button" className="btn start-btn" onClick={e => { window.location.reload() }}>刷新</button>
                                </div>

                                <div className='pt-5'>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only"> </span>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='text-center'>
                            {self.attr.loadCompleted == 2 && <>
                                <h1>{subject && <span className=''>{subject.name} </span>} </h1>

                                <div className='p-5'>

                                    {self.attr.loadCompleted == 2 &&
                                        <button type="button" className="btn start-btn" onClick={this.getCheckContent}>开 始</button>
                                    }

                                </div>
                            </>}


                        </div>
                    </div>
                }


                {end &&
                    <div className=' d-flex align-items-center  vh-100 justify-content-center'>

                        <div className='text-center'>
                            <h1>{subject && <span className=''>{message}</span>}  </h1>
                            <div className='p-5'>
                                <button type="button" className="btn start-btn" onClick={e => { this.startGame(); }}>重新测试</button>
                            </div>
                        </div>
                    </div>
                }




                <div className={` flex-column write_pad default-none ${start ? 'd-flex' : ''} show-${start}${submited} end-${end}`} >


                    <div className='head-tool-w'>

                        <div className="d-flex justify-content-between t-up">
                            <div className="col1">
                                <button type="button" className="btn " onClick={e => { window.history.back() }}>结束</button>
                            </div>
                            <div className="col2">
                                {subject && <span className=''>{subject.name} </span>}
                            </div>
                            <div className="col3">
                                {message.length < 1 &&
                                    <span><button type="button" className="btn btn-secondary mr-2" onClick={this.getCheckContent}>开始测试</button> &nbsp;</span>
                                }
                                {score > -1 &&
                                    <button type="button" className="btn btn-info " onClick={this.getCheckContent}>下一题</button>
                                }
                            </div>
                        </div>


                        <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar  bg-info" style={{ width: `${progress}%` }} >{progressString} {Math.round(progress)}%</div>
                        </div>
                    </div>

                    <div className='d-flex  flex-column justify-content-between pad-w'>

                        <div className="   pad-w-up ">
                            {submited != 0 && message.length > 0 &&
                                <>
                                    <div className='sent ' >
                                        <div className='sent-t'>《{message}》：

                                            <a className=" mr-2" onClick={this.getPrompt}>提示({promptNum})</a>


                                        </div>
                                        <div className='sent-b p-2'>

                                            {this.attr.subject_con['con'].length > 0 &&
                                                <div>

                                                    {this.attr.subject_con['con'].map((item, index) => (
                                                        <div key={index} className="poetry-line text-center">
                                                            {self.attr.checkLineIndex == index &&
                                                                <>
                                                                    {item.split('').map((a, b) => (
                                                                        <span className='poe-char' key={b} onClick={e => this.selectInputWord(b)} >
                                                                            <PlaidSvg item={a} index={b} space={true} word={this.attr.whriteWords[b]} windex={this.attr.whriteIndex} />
                                                                        </span>))}

                                                                </>
                                                            }

                                                            {self.attr.checkLineIndex != index &&
                                                                <>
                                                                    {item.split('').map((a, b) => (
                                                                        <span className='poe-char' key={b}>
                                                                            <PlaidSvg item={a} index={b} space={false} />
                                                                        </span>))}

                                                                </>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            }


                                        </div>
                                        {/* <div className='p-2'>写出上下文诗句，会自动填写到米字</div> */}
                                    </div>

                                </>
                            }

                            {submited == 0 &&

                                <div className='result d-flex flex-column  result text-center align-items-center  vh-100 '>
                                    {score >= 95 && <div style={{ width: '200px' }} className='' ><StarsPlay /></div>}
                                    <span className='r-score2'>本题得分{score} 分</span>&nbsp;
                                    {score < 95 && <span className='r-score2'>加油，请继续努力</span>}
                                    <div className='pt-2'>我的答案：</div>
                                    <h3 className=''>{result}</h3>
                                    <div className=''>正确:{this.attr.checkLine}</div>

                                    <div className='text-center pt-3' >
                                        <button type="button" className="btn btn-light " onClick={this.getCheckContent}>下一题</button>

                                    </div>
                                </div>
                            }
                        </div>
                        <div className="  pad-w-down ">

                            <div className={`t-w-box default-none show-${start}${submited}`}>

                                {identiMessage.length > 0 && submited == 2 &&
                                    <div className='text-center p-3' >
                                        <h2>{identiMessage}</h2>
                                        <button type="button" className="btn btn-primary btn-submit" onClick={this.handleQuerySelect}>确定</button>

                                    </div>

                                }


                                <div className='show-result'>
                                    {showTexts.length > 0 &&
                                        showTexts.map((item, index) =>
                                            <span className='' key={index} onClick={e => this.selectText(e)}>
                                                {item}
                                            </span>
                                        )
                                    }
                                </div>
                                <div className='show-result2'>
                                    {showTexts2.length > 0 &&
                                        showTexts2.map((item, index) =>
                                            <span className='' key={index} onClick={e => this.selectText(e)} >
                                                {item}
                                            </span>
                                        )
                                    }
                                </div>
                                <div className="ci-box ">
                                    <div className="lt-2rb line"></div>
                                    <div className="rt-2lb line"></div>
                                    <div className="rm-2lm line"></div>
                                    <div className="tm-2bm line"></div>
                                    <div className="ci">
                                        <span className="wd"> </span>
                                    </div>
                                </div>
                                <canvas id="canvas" />
                                <div className="canvas-tool">
                                    <div className="d-flex justify-content-between">
                                        <button className="btn " onClick={this.clearBoard} >清除</button>
                                        <button className="btn " onClick={this.undoBoard}  >回退</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>



            </div>)
    }

}

export { PoetryBoard };