
import { http } from '../utils/http'


import React, { useRef, useState, useEffect } from "react";


import { useReactMediaRecorder, ReactMediaRecorder } from "react-media-recorder";


import axios from 'axios';



var BatchUploader = {

    upload_file_id: String(Math.random() * (10000 - 100000) + 100000),
    file_bold_cache: {},
    alert_num: 0,
    percentNum: 0,
    totalNum: 0,
    percent: 0,
    callback: e => { this.uploadProcess(e); },
    uploadProcess: e => { },
    warnPrompt: e => { },
    startDate: new Date(),
    totalSize: 0,// blob.size;	//文件总大小
    start: 0,				//每次上传的开始字节
    end: 1024 * 1024,	//每次上传的结尾字节
    //给个文件名的随机数，避免重复， 也可以用分割的份数ID来代替，可能更好一些。
    //let r = String(myDate.getTime()); //String( Math.random()*(10000-100000) + 100000 );
    url: `/api/recoaudio?id=`,
    prompt: function (t) { this.warnPrompt(t); },


    uploader: function (key, blob, start, end, totalSize, randomvalue, callbackForPercent) {

        // 获取blob数据
        // const blob = new Blob([postString], { type: 'text/plain;charset=utf-8' })

        // 创建FormData对象
        let formData = new FormData();
        let self = this;

        //上传时，文件名需要把重要信息标记在一起
        formData.append('upfile', blob, 'f_' + start + '_' + end + '_' + totalSize + '.mp3'); // 第一个参数是后台接收的文件参数名，第二个参数是blob数据，第三个参数是文件名
        //文件唯一标识
        formData.append("r", randomvalue);
        // 发送ajax请求
        axios.post(this.url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            //console.log('respons:' + key); 
            //console.log(response.data);
            if (response.data.code == 200) {

                //console.log("updoad sucess!");

                //删除缓存
                delete self.file_bold_cache[key];

                // 处理响应数据
                //addAudioElement2(blob);
                callbackForPercent(key);

                self.percentNum = self.percentNum + 1;

                //日志
                //console.log("file_bold_cache.length=" + Object.keys(file_bold_cache).length);
                if (self.alert_num > 0) {
                    self.alert_num = self.alert_num - 1;
                }
                //只有有一个正常，说明网络正常了，可以上传成功，清空提示
                self.prompt('');

            }

        }).catch(error => {
            //记录错误数量alert_num
            self.alert_num = self.alert_num + 1;
            // 处理错误
            self.prompt("上传异常(" + String(self.alert_num) + ")，请检测网络信号，网络正常(30秒后），会自动上传！");

        });

    },

    callbackForPercent: function (key) {
        //this.percentNum++; 
    },
    //更新进度
    updatePercent: function () {

        let self = this;

        console.log(this.startDate);

        //防止长时间的上传
        console.log("new Date().getTime()-startDate.getTime()=" + (new Date().getTime() - this.startDate.getTime()))
        if ((new Date().getTime() - this.startDate.getTime()) / 1000 > (this.totalNum + 1) * 30) {
            return;
        }

        //如果进度条上传到99时，认为成功了。
        if (this.percent > 99) {
            this.callback(100);
        } else {

            //没有分割文件，直接上传完成
            if (this.totalNum < 1) {

            } else {
                this.percent = parseInt(this.percentNum * 100 / this.totalNum);
                console.log("percent=" + this.percent + " percentNum=" + this.percentNum + "totalNum=" + this.totalNum);
                this.callback(this.percent);
                setTimeout(function () { self.updatePercent(); }, 300);
            }

        }

        //检测上传的部分文件，有没有上传成功， 如果30秒没有成功， 则需要重新上传
        for (var val in this.file_bold_cache) {
            //console.log(val + " use seconds=" + (new Date().getTime() - this.file_bold_cache[val][7].getTime()) / 1000);//输出 

            //超过30秒没有上传完成，重新上传
            if ((new Date().getTime() - this.file_bold_cache[val][7].getTime()) / 1000 > 30) {
                this.startDate = new Date();
                this.file_bold_cache[val][7] = new Date();
                let p = this.file_bold_cache[val]
                this.uploader(p[0], p[1], p[2], p[3], p[4], p[5], this.callbackForPercent);
            }
        }
    },
    submitAudio: function (blob) {

        const LENGTH = 1024 * 100;	//每次上传的大小 
        this.totalSize = blob.size;	//文件总大小
        this.start = 0;				//每次上传的开始字节
        this.end = this.start + LENGTH;	//每次上传的结尾字节

        this.upload_file_id = String(Math.random() * (10000 - 100000) + 100000);

        this.percentNum = 0;

        //总份数
        this.totalNum = parseInt(this.totalSize / (this.end - this.start));
        this.percent = 0;
        //let updateNum = 0;
        this.startDate = new Date();


        console.log("totalSize=" + this.totalSize);


        //更新进度条
        this.updatePercent();


        //开始上传
        while (this.start < this.totalSize) {

            if (this.end > this.totalSize) {
                this.end = this.totalSize;
            }
            //分割文件
            let blob1 = blob.slice(this.start, this.end); //sliceBlob(blob,start, end, blob.type);//file.slice(start, end);//根据长度截取每次需要上传的数据
            let key = String(this.start) + String(this.end) + String(this.totalSize) + String(this.upload_file_id);
            if (this.file_bold_cache.hasOwnProperty(key)) {

            } else {
                //保存部分缓存，并上传
                this.file_bold_cache[key] = [key, blob1, this.start, this.end, this.totalSize, this.upload_file_id, this.callbackForPercent, new Date()];
                this.uploader(key, blob1, this.start, this.end, this.totalSize, this.upload_file_id, this.callbackForPercent);
            }


            //callback(start, end, totalSize);
            // if( end > 1){
            //     return;
            // }

            //重新设置开始和结尾
            this.start = this.end;
            this.end = this.start + LENGTH;
        }
    }

}





function AudioRecorder({ uploadProcess, warnPrompt }) {
    const [state, setState] = useState({
        subject: null,
        records: [],
        message: "",
        score: 0,
        info: '',
        percent: -1
    });
    const batchUploader = Object.create(BatchUploader);

    const attr = useRef({ id: new URLSearchParams(window.location.search).get('id'), signpad: null, undoData: [], editor: null, upload_num: 0, audio_context: null, recorder: null });
    batchUploader.url = batchUploader.url + attr.current.id;
    batchUploader.callback = uploadProcess;
    batchUploader.prompt = warnPrompt;

    const {
        status,
        startRecording,
        stopRecording,
        pauseRecording,
        mediaBlobUrl
    } = useReactMediaRecorder({
        video: false,
        audio: true,
        echoCancellation: true,
        onStop: (blobUrl, blob) => {
            console.log(blobUrl, blob);
            batchUploader.submitAudio(blob);
        }
    });


    useEffect(() => {
        loadTask(attr.current.id);
        return () => {
            console.log('DoVoice1 componentWillUnmount stopRecording');
            stopRecording()
        };
    }, []);

    // ... Convert other methods to functions ...

    function loadTask(id) {
        // ... implementation ...
    }

    function showStatus(status) {
        console.log(status)
        if (status == 'idle') {
            return '请点击开始录音';
        }
        if (status == 'recording') {
            return '录音中';
        } else if (status == 'paused') {
            return '录音暂停';
        } else if (status == 'stopped') {
            return '录音停止';
        }
    }


    // ... Rest of the component logic ...

    return (
        <>
            <div>{showStatus(status)}</div>

            {status != 'stopped' && <>
                <button onClick={startRecording}>开始录音</button>

                <button onClick={pauseRecording}>暂停录音</button>
                <button onClick={stopRecording}>停止录音并上传</button>

            </>
            }

        </>
    );
}

class DoVoice1 extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)

        this.state = {
            subject: null,
            records: [],
            message: "",
            score: 0,
            info: '',
            percent: -1
        }


        this.attr = { id: params.id, "signpad": null, "undoData": [], "editor": null, "upload_num": 0, audio_context: null, recorder: null };
        this.loadTask = this.loadTask.bind(this);
        this.uploadProcess = this.uploadProcess.bind(this);
        this.warnPrompt = this.warnPrompt.bind(this);
        this.bindComponent = this.bindComponent.bind(this);
    }

    componentDidMount() {
        const self = this
        this.loadTask(this.attr.id);


    }
    componentWillUnmount() {
        console.log('Dotask componentWillUnmount stopRecording');



    }

    loadTask(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/task?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }
    uploadProcess(percent) {
        this.setState({ percent: percent });
    }

    warnPrompt(info) {
        this.setState({ info: info });
    }

    bindComponent(com) {
        this.attr.audioComponent = com;
    }



    render() {
        const { records, message, score, subject, percent, info } = this.state;
        const self = this;
        console.log(records)




        return (<div className='disable-select'>


            <div className=' pb-5 '>
                <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>
            </div>



            <div className=' p-5'>
                {percent < 1 &&
                    <AudioRecorder uploadProcess={this.uploadProcess} warnPrompt={this.warnPrompt} />
                }

                {/* 

                <ReactMediaRecorder
                audio
                render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream  }) => {

                        console.log(previewStream );
                    return (
                    <div>
                    <p>{status}</p>
                    <button onClick={startRecording}>Start Recording</button>
                    <button onClick={stopRecording}>Stop Recording</button>
                    <audio src={mediaBlobUrl} controls autoPlay   />
                    </div>
                )}}
                /> */}


                {/* <button onClick={e => { this.startRecording(e.target) }}>record</button>
                <button onClick={e => { this.stopRecording(e.target) }}  >stop</button> */}

                {info.length > 0 &&
                    <div className="alert alert-warning" role="alert">
                        {info}
                    </div>
                }

                <div id="uploadstate" >

                    {percent >= 0 && percent < 100 &&
                        <div className='' >
                            <h5>上传中..</h5>
                            <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar  bg-info" style={{ width: `${percent}%` }} >{percent}%</div>
                            </div>
                        </div>
                    }
                    {percent >= 100 && <div> 上传完成.</div>}


                </div>


                <div className='pad-text'>{message}</div>
                <div id="audio_list" >

                </div>


            </div>

        </div>)
    }

}

export { DoVoice1 };