
import { http } from '../utils/http'
import React from "react";
import { Outlet, Link } from "react-router-dom";


class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            records: [],
            u: [],
            c: [],
            b: [],
            username: '',
            score: 0,
            progress: 0,
            message: ""
        }
        this.attr = { "signpad": null, "undoData": [], "timeoutID": 0, 'resetNumber':5 }

        this.loadRecords = this.loadRecords.bind(this)
        this.loadScoreRecords = this.loadScoreRecords.bind(this)

        this.buildLink = this.buildLink.bind(this);
        this.buildViewLink = this.buildViewLink.bind(this);
        this.start = this.start.bind(this)


		this.resetTask = this.resetTask.bind(this);

    }

    componentDidMount() {
        const self = this
        this.loadRecords();
        this.start();
    }
    start() {
        window.clearInterval(this.attr.timeoutID);
        //this.attr.timeoutID = setInterval(this.loadRecords, 30000);
    }

	resetTask(){
		
		//alert('确定要重置吗？');


 
		this.attr.resetNumber = this.attr.resetNumber - 1;

		const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/resettaskall`, requestOptions, function (data) {
            console.log(  data)
            alert(data.data.message);
			self.loadRecords();
        })
	}


    loadRecords() {
        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 0 })
        };
        http.YGet(`/api/tasks`, requestOptions, function (data) {
            console.log(new Date(), data)
            // self.setState({
            //     records: data.data.data
            // })

            let u = data.data.u;
            let c = data.data.c;
            let b = data.data.b;
            self.setState({
                u: u,
                c: c,
                b: b,
                progress: data.data.progress,
                username: data.data.username,
                score: data.data.score
            })
        })
    }

    loadScoreRecords() {

    }

    buildLink(item) {

        let link = "/dotask/" + item.id + "?id=" + item.id;
        if (item.type_id == 2) {
            link = "/dotaskimage/" + item.id + "?id=" + item.id;
        } else if (item.type_id == 3) {
            link = "/chinesetest/" + item.id + "?id=" + item.id;
        } else if (item.type_id == 4) {
            link = "/engtest/" + item.id + "?id=" + item.id;
        } else if (item.type_id == 5) {
            link = "/pingyintest/" + item.id + "?id=" + item.id;
        } else if (item.type_id == 6) {
            link = "/poetrytest/" + item.id + "?id=" + item.id;
        } else if (item.type_id == 7) {
            link = "/engsh/" + item.id + "?id=" + item.id;
        }
        return link;
    }
    buildViewLink(item) {

        let link = "/taskview/" + item.id + "?id=" + item.id;
        if (item.type_id == 2) {
            link = "/taskview/" + item.id + "?id=" + item.id;
        }
        return link;
    }

    render() {
        const { records, message, u, c, b, progress } = this.state;

        return (<div className='  disable-select  '>

            <div className='d-flex flex-column vh-100 justify-content-between' >

                <div className=''>
                    <div className="d-flex justify-content-between header-user">
                        <span className="u-name"  >{this.state.username}</span>
                        <span className="u-money" id="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                            </svg>
                            {this.state.score}
                        </span>
                    </div>


                    <div className='task-list'>

                        {u && u.length > 0 &&
                            <div>
                                <ol>
                                    {u.map((item, index) =>
                                        <li className='item' key={index} >




                                            <div className="d-flex flex-row">

                                                <div className="flex-shrink-0  ">
                                                    <Link to={this.buildLink(item)} className='t1'>{item.name}</Link>
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                                        </svg>
                                                        {item.score}
                                                    </span>


                                                </div>

                                                <div className="flex-grow-1  px-2 text-end">
                                                    <div className='pe-1'>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" v="bi bi-three-dots" viewBox="0 0 16 16">
                                                            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>



                                        </li>
                                    )
                                    }
                                </ol>
                            </div>
                        }

                        {b && b.length > 0 &&
                            <div className='done'>
                                <ol>
                                    {b.map((item, index) =>
                                        <li className='item' key={index} >

                                            <div className="d-flex flex-row">

                                                <div className="flex-shrink-0  ">
                                                    <div><Link to={this.buildViewLink(item)}>{item.name}</Link> </div>

                                                </div>

                                                <div className="flex-grow-1  px-2 text-end">
                                                    <div>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                                                        </svg>
                                                    </div>

                                                </div>
                                            </div>


                                        </li>
                                    )
                                    }
                                </ol>
                            </div>
                        }


                        {c && c.length > 0 &&
                            <div className='done'>
                                <ol>
                                    {c.map((item, index) =>
                                        <li className='item' key={index} >
                                            {item.name}

                                        </li>
                                    )
                                    }
                                </ol>
                            </div>
                        }


                    </div>
                </div>

                {/* 

                    <div className="container-fluid ">
                        <div className="row-fluid">
                            <div className="span12 task-list">
                                <div className="page-header">
                                    <blockquote>
                                        <div>
                                            {progress < 100 &&

                                                <span className='smile bi-act'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                                                    </svg>
                                                </span>

                                            }
                                            {progress >= 100 &&

                                                <span className='smile bi-act'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
                                                    </svg>
                                                </span>

                                            }



                                            今完成<span className='task_num'>{c.length}</span>项，未完<span className='task_num'>{u.length}</span>项。
                                            <a href="/chat/1?id=1">聊天</a>
                                        </div>
                                    </blockquote>
                                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar  bg-info" style={{ width: `${progress}%` }} >{Math.round(progress)}%</div>
                                    </div>
                                </div>

                                <h5>
                                    未完成的任务：
                                </h5>

                                {u && u.length > 0 &&
                                    <div>
                                        <ol>
                                            {u.map((item, index) =>
                                                <li className='item' key={index} >
                                                    <span className='dot'></span>

                                                    <Link to={this.buildLink(item)} className='t1'>{item.name}</Link>
                                                </li>
                                            )
                                            }
                                        </ol>
                                    </div>
                                }
                                <h5>
                                    已提交待审核：
                                </h5>

                                {b && b.length > 0 &&
                                    <div>
                                        <ol>
                                            {b.map((item, index) =>
                                                <li className='item' key={index} >

                                                    <span className='dot1'></span>
                                                    <Link to={this.buildViewLink(item)}>{item.name}</Link>
                                                </li>
                                            )
                                            }
                                        </ol>
                                    </div>
                                }


                                <h5>
                                    已经完成的任务：
                                </h5>
                                {c && c.length > 0 &&
                                    <div>
                                        <ol>
                                            {c.map((item, index) =>
                                                <li className='item' key={index} >
                                                    <span className='dot2'></span>
                                                    {item.name}
                                                </li>
                                            )
                                            }
                                        </ol>
                                    </div>
                                }



                            </div>
                        </div>
                    </div>


                </div> */}


                <div className='footbar border-top'>


                    <ul className="nav justify-content-center  pt-3 pb-3">
                        <li className="nav-item a">
                            <Link to='/scoreproduct/1?id=1' className="nav-link px-2 text-muted  ">积分兑换</Link>

                        </li>
                        <li className="nav-item">

                            <Link to='/chat/1?id=1' className="nav-link px-2 text-muted  b">AI</Link>



                        </li>
						<li className="nav-item a ms-3">
                            <Link to='#' className="nav-link px-2 text-muted   " onClick={this.resetTask}>重置</Link>

                        </li>
                    </ul>


                </div>
            </div>


        </div>)
    }

}

export { HomePage };