
import { http } from '../utils/http'
import _ from 'lodash'
import React from "react";

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Editor, Tldraw, useValue, exportAs, exportToBlob, DefaultBrush, DefaultDashStyle, DefaultSizeStyle } from 'tldraw'

import 'tldraw/tldraw.css'

const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

/* 压缩base64图片，怎么压缩base64是题外话，这里不赘述 */
function compress(
    base64,        // 源图片
    rate,          // 缩放比例
    callback       // 回调
) {
    //处理缩放，转格式
    var _img = new Image();
    _img.src = base64;
    _img.onload = function () {
        var _canvas = document.createElement("canvas");
        var w = this.width / rate;
        var h = this.height / rate;
        _canvas.setAttribute("width", w);
        _canvas.setAttribute("height", h);
        _canvas.getContext("2d").drawImage(this, 0, 0, w, h);
        var base64 = _canvas.toDataURL("image/jpeg");
        _canvas.toBlob(function (blob) {
            if (blob.size > 750 * 1334) {        //如果还大，继续压缩
                compress(base64, rate, callback);
            } else {
                callback(base64);
            }
        }, "image/jpeg");
    }
}



const ExampleComponent = () => {
    const recorderControls = useAudioRecorder()
    const addAudioElement = (blob) => {
        // const url = URL.createObjectURL(blob);
        // const audio = document.createElement("audio");
        // audio.src = url;
        // audio.controls = true;
        // document.body.appendChild(audio);
        // console.log(url)

        /*
        blob文件对象，可以用FileReader读取出内容
        ，或者用FormData上传，本例直接上传二进制文件
        ，对于普通application/x-www-form-urlencoded表单上传
        ，请参考github里面的例子
        */
        var form = new FormData();
        form.append("upfile", blob, "recorder.mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3

        //直接用ajax上传
        var xhr = new XMLHttpRequest();
        xhr.open("POST", `/api/recoaudio?id=0`);//这个假地址在控制台network中能看到请求数据和格式，请求结果无关紧要
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if( xhr.status == 200 ){

                }else{
                    alert("上传失败，请联系开发人员处理，感谢！");
                }
                //alert(xhr.status == 200 ? "上传成功" : "保存失败，请联系开发人员处理，感谢！");
            }
        }
        xhr.send(form);


    };

    return (
        <div>
            <AudioRecorder
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                }}
                recorderControls={recorderControls}
               
                downloadFileExtension="mp3"
            />
            <button onClick={recorderControls.stopRecording}>完成</button>
        </div>
    )
}
class SubjectView extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match)

        console.log(this.props)
        console.log("this.props.match.params.data=" + this.props.match)

        const searchParams = window.location.search.substring(1).split('&');
        const params = {};
        for (const param of searchParams) {
            const [key, value] = param.split('=');
            params[key] = value;
        }
        console.log(params)




        this.state = {
            subject: null,
            records: [],
            message: " 请在右边写出您的内容，完成后提交。",
            score: 0
        }
        this.attr = { id: params.id, "signpad": null, "undoData": [], "editor": null }

        this.handleQuerySelect = this.handleQuerySelect.bind(this)
        this.loadRecords = this.loadRecords.bind(this)





    }

    componentDidMount() {

        const self = this

        // var canvas = document.querySelector("canvas");
        // var signaturePad = new SignaturePad(canvas);
        // this.attr['signpad'] = signaturePad;
        // signaturePad.addEventListener("endStroke", () => {
        //     // clear undoData when new data is added
        //     self.attr.undoData = [];
        //   });


        // //窗口缩放事件
        // window.onresize = function () {
        //     self.redraw()
        // }
        // self.redraw()
        this.loadRecords()

        this.loadSubject(this.attr.id);
    }

    loadSubject(id) {

        const self = this;
        self.setState({ subject: null })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': id })
        };
        http.YGet(`/api/subject?id=` + id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                subject: data.data.data
            })
        })
    }

    loadRecords() {

        const self = this;
        self.setState({ records: [] })
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': self.attr.id })
        };
        http.YGet(`/api/records?id=` + self.attr.id, requestOptions, function (data) {
            console.log(data)
            self.setState({
                records: data.data.data
            })
        })
    }


    handleQuerySelect() {
        const self = this;
        let shapes = this.attr.editor.getCurrentPageShapeIds();
        if (shapes.size < 1) {
            window.alert("请在右边写出您的内容，完成后提交。")
            return null;
        }
        console.log(shapes)
        const blob = exportToBlob({ editor: this.attr.editor, shapes, format: "jpeg" }).then(function (blob) {
            //console.log(blob)
            const file = new File([blob], 'a', { type: blob.type });
            let b64Data = blobToBase64(file).then(function (b64Data) {
                //console.log(b64Data)
                //self.handleQuerySelect(b64Data)


                // const requestOptions = {
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json' },
                //     body: JSON.stringify({ 'id': 0, 'data': b64Data })
                // };

                // self.setState({ message: String('正在识别中..') })
                // http.YGet(`/api/recognize`, requestOptions, function (data) {
                //     var content = String(data.data.message)
                //     if (content.length < 1) {
                //         self.setState({ message: String('没有识别到书写的内容，请重新书写') })
                //     } else {
                //         console.log(data)
                //         self.setState({
                //             message: String(data.data.message)
                //         })
                //     }

                // })

                console.log("b64Data.length", b64Data.length)


                compress(b64Data, 4, function (base64) {
                    //console.log(base64) 
                    console.log("base64.length", base64.length)
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'id': 0, 'data': base64 })
                    };

                    self.setState({ message: String('正在识别中..') })
                    http.YGet(`/api/recognize`, requestOptions, function (data) {
                        var content = String(data.data.message)
                        if (content.length < 1) {
                            self.setState({ message: String('没有识别到书写的内容，请重新书写') })
                        } else {
                            console.log(data)
                            self.setState({
                                message: String(data.data.message),
                                score: data.data.score
                            })
                        }

                    })

                })
            })
        });

        //var data = []

    }


    render() {
        const { records, message, score, subject } = this.state;
        const self = this;
        console.log(records)
        return (<div className='disable-select'>


            <div className="d-flex flex-column write_pad ">


                <div className='head-tool'>
                    <div className=" align-items-center t-box">
                        <div className="t-menu ">
                            <button type="button" className="btn " onClick={e => { window.history.back() }}>返回</button>

                        </div>
                        <div className="a-auto t-submit">
                            {subject && <span className=''>{subject.name} </span>}
                            <span className='r-score2'>{score} 分</span>&nbsp;


                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row pad-tool'>
                    <div className="   bd-highlight _left">

                        <ExampleComponent ></ExampleComponent>

                        <div className='pad-text'> {subject && <div  >{subject.contents}</div>}</div>


                        {subject && subject.prompt && <div className='p-2'> <br /><h5>提示：</h5>{subject.prompt} </div>}

                        {subject && subject.record1 && <div className='p-2'> <br /><h5>默写：</h5>{subject.record1.base64} </div>}

                        <hr />
                        {records && records.length > 0 &&
                            <div>
                                <ul>
                                    {records.map((item, index) =>
                                        <li className='query_item' key={index} >
                                            {item.result}, {item.create_time}
                                        </li>
                                    )
                                    }
                                </ul>
                            </div>
                        }




                    </div>
                    <div className="  bd-highlight _right sv">

                        <img src=""></img>
                        {subject && subject.record && <img src={`data:image/jpeg;base64,${subject.record.base64}`} ></img>}
                    </div>
                </div>
            </div>


        </div>)
    }

}

export { SubjectView };